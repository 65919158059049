import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState } from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import { enquiryurl } from '../Constants/urls';

const Demoformhome = ({ onSubmit, productName, closeModal }) => {
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        // email: '',
        product_name: productName,
        is_demo: 'false',
        description: '',
        address: '',
        enquiry_reason: '',
    });

    const notify = (data) => toast(data);
    const [errors, setErrors] = useState({
        name: '',
        phone_number: '',
        // email: '',
        // product_name: '',z
        description: '',
        address: '',
        enquiry_reason: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Reset error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        // Update description based on enquiry_reason
        if (name === 'enquiry_reason') {
            setFormData((prevData) => ({
                ...prevData,
                description: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation
        let formIsValid = true;
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const value = formData[key]?.trim(); // Safely access and trim value
            if (value === '') {
                formIsValid = false;
                newErrors[key] = `${getFieldName(key)} is required`;
            }
        });

            // Validate enquiry_reason specifically
    if (formData.enquiry_reason === '') {
        formIsValid = false;
        newErrors['enquiry_reason'] = 'Enquiry Reason is required';
    }


        if (!formIsValid) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }


        try {

            const token = 'e606743946ca17935cdaf8074a5a6bfce1674f2b';
            const response = await fetch(enquiryurl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                notify('Form submitted successfully');
                setFormData({
                    name: '',
                    phone_number: '',
                    product_name: '', // Reset other fields if needed
                    is_demo: 'false',
                    description: '',
                    address: ''
                });
                closeModal();
            } else {
                notify('Error submitting form');
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    const getFieldName = (fieldName) => {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className='mt-3'>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <h3 className="card-title text-start mt-3 mb-3 productdetailsheading"><center>Request a Demo</center></h3>
                        </div>
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Full Name"
                                name="name"
                                type="text"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                    // style: { fontSize: '16px' },
                                }}
                                value={formData.name}
                                onChange={handleChange}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red', },
                                }}
                                fullWidth
                            />
                            {errors.name && <div className="error-message text-end">{errors.name}</div>}
                        </div>
                    </Box>
                </div>
                <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Phone"
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleChange}
                                type="number"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red' },
                                }}
                                fullWidth
                            />
                            {errors.phone_number && <div className="error-message text-end">{errors.phone_number}</div>}
                        </div>
                    </Box>
                </div>
                {/* <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                type="email"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red' },
                                }}
                                fullWidth
                            />
                        </div>
                    </Box>
                    {errors.email && <div className="error-message text-end">{errors.email}</div>}
                </div> */}
                {/* <div class="mb-2">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-number"
                                label="Product Name.."
                                name="product_name"
                                value={formData.product_name}
                                onChange={handleChange}
                                type="text"
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red' },
                                }}
                                fullWidth
                            />
                        </div>
                    </Box>
                </div> */}
                {/* <div class="mb-2">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextareaAutosize
                                id="outlined-number"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                aria-label="Description"
                                placeholder="Description.."
                                className='text-area_contact'
                                minRows={3}
                                color="success"
                                focused
                                style={{
                                    borderRadius: '20px', borderColor: 'success', width: '100%',
                                    maxWidth: '620px', padding: '10px'
                                }}
                            />
                        </div>
                    </Box>
                </div> */}
                <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextareaAutosize
                                id="outlined-number"
                                aria-label="Address"
                                placeholder="Address.."
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                className='text-area_contact'
                                minRows={3}
                                color="success"
                                focused
                                style={{
                                    borderRadius: '20px', borderColor: 'success', width: '100%',
                                    maxWidth: '620px', padding: '10px'
                                }}
                            />
                        </div>
                    </Box>
                    {errors.address && <div className="error-message text-end">{errors.address}</div>}
                </div>
                <div className="mb-4">
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-select-reason"
                                select
                                label="Enquiry Reason"
                                name="enquiry_reason"
                                value={formData.enquiry_reason}
                                onChange={handleChange}
                                color="success"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                focused
                                InputProps={{
                                    style: { borderRadius: '20px', borderColor: 'red' },
                                }}
                                fullWidth
                                SelectProps={{
                                    displayEmpty: true,
                                    renderValue: (value) => (value ? value : '-  Select an enquiry reason  -'),
                                }}

                            >
                                <MenuItem value="" disabled>
                                    - Select an enquiry reason -
                                </MenuItem>
                                <MenuItem value="General Product Inquiry">Product Inquiry</MenuItem>
                                <MenuItem value="Fasalam App">Fasalam App</MenuItem>
                                <MenuItem value="Installation">Installation</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                            {errors.enquiry_reason && <div className="error-message text-end">{errors.enquiry_reason}</div>}
                        </div>
                    </Box>
                </div>
                {formData.enquiry_reason === 'General Product Inquiry' && (
                    <div className="mb-4">
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    id="outlined-select-product"
                                    select
                                    label="Select Product"
                                    name="product_name"
                                    value={formData.product_name}
                                    onChange={handleChange}
                                    color="success"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    focused
                                    InputProps={{
                                        style: { borderRadius: '20px', borderColor: 'red' },
                                    }}
                                    fullWidth
                                    SelectProps={{
                                        displayEmpty: true,
                                        renderValue: (value) => (value ? value : '-  Select a Product  -'),
                                    }}
                                >

                                    <MenuItem value="GSM Motor Controller">GSM Motor Controller</MenuItem>
                                    <MenuItem value="Farm Weather Station">Farm Weather Station</MenuItem>
                                    <MenuItem value="Farm Data Logger">Farm Data Logger</MenuItem>
                                    <MenuItem value="Rapid Soil Tester">Rapid Soil Tester</MenuItem>
                                    <MenuItem value="IoT Mobile Motor Starter">IoT Mobile Motor Starter</MenuItem>
                                </TextField>
                            </div>
                        </Box>
                    </div>
                )}
                {formData.enquiry_reason === 'Other' && (
                    <div className="mb-4">
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextareaAutosize
                                    id="outlined-number"
                                    aria-label="Description"
                                    placeholder="Description.."
                                    name="description"
                                    // value={formData.description}
                                    onChange={handleChange}
                                    className='text-area_contact'
                                    minRows={3}
                                    color="success"
                                    focused
                                    style={{
                                        borderRadius: '20px', borderColor: 'success', width: '100%',
                                        maxWidth: '620px', padding: '10px'
                                    }}
                                />
                            </div>
                        </Box>
                        {errors.address && <div className="error-message text-end">{errors.address}</div>}
                    </div>
                )}
                <div className="mb-5">
                    <button
                        type="submit"
                        className="btn btn-primary submitreview_contactus"
                        style={{
                            width: '100%',
                            maxWidth: '620px'
                        }}
                        disabled={loading} >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Demoformhome
