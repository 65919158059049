import React, { useEffect, useRef, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { blogdata } from "../Constants/Blogdata";
import { Title, Meta, HeadProvider, Link as HeadLink } from "react-head";
import loading_image from "../Images/blog/loader_img.gif";
import axios from "axios";
import { allblog, serverToken } from "../Constants/urls";

const Allblogs = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleHoverExplore = (index) => {
    setHoveredCard(index);
  };
  const [Allblog, setAllblog] = useState();

  // console.log(Allblog)
  // console.log(automationProduct)
  const fetchData = async (page = 1) => {
    try {
      //   setLoading(true);
      const token = serverToken;
      const response = await axios.get(allblog, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setAllblog(response.data.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const toComponentB = (data) => {
    navigate(`/blog/${encodeURIComponent(data.slug)}/`, { state: { data } });
  };

  function truncateHtml(html, maxLength) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const textContent = doc.body.textContent || "";
    return textContent.length > maxLength
      ? `${textContent.substring(0, 40)}...`
      : textContent;
  }

  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Rashail Agro Blog: Your Resource for Precision Farming</title>
                <meta name="description" content="Gain insights on precision farming, IoT, and agricultural technology from Rashail Agro's blog." />
                <base target="_blank" href="https://rashailagro.in/blog/all-blogs/" />
                <link rel="canonical" href="https://rashailagro.in/blog/all-blogs/" />
                <meta name="keywords" content="precision farming, iot in farming, agriculture technology, farming technology, fasalm app, faslam mobile app, fasalam, agriculture tools, agriculture machinery, farming equipments, farming tools, krishi, advance farming, product details, farm weather stations, farming tips, ai in farming, ai in agriculture, irrigation, farm health, soil health" />
                <meta property="og:title" content="Rashail Agro Blog: Your Resource for Precision Farming" />
                <meta property="og:description" content="Gain insights on precision farming, IoT, and agricultural technology from Rashail Agro's blog." />
                <meta property="og:url" content="https://rashailagro.in/blog/all-blogs/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro Blog: Your Resource for Precision Farming" />
                <meta name="twitter:description" content="Gain insights on precision farming, IoT, and agricultural technology from Rashail Agro's blog." />
                <meta property="twitter:url" content="https://rashailagro.in/blog/all-blogs/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      <HeadProvider>
        <Title>Rashail Agro Blog: Your Resource for Precision Farming</Title>
        <Meta
          name="description"
          content="Gain insights on precision farming, IoT, and agricultural technology from Rashail Agro's blog."
        />
        <base target="_blank" href="https://rashailagro.in/blog/all-blogs/" />
        <HeadLink
          rel="canonical"
          content="https://rashailagro.in/blog/all-blogs/"
        />
        <Meta
          name="keywords"
          content="precision farming, iot in farming, agriculture technology, farming technology, fasalm app, faslam mobile app, fasalam, agriculture tools, agriculture machinery, farming equipments, farming tools, krishi, advance farming, product details, farm weather stations, farming tips, ai in farming, ai in agriculture, irrigation, farm health, soil health"
        />
        <Meta
          property="og:title"
          content="Rashail Agro Blog: Your Resource for Precision Farming"
        />
        <Meta
          property="og:description"
          content="Gain insights on precision farming, IoT, and agricultural technology from Rashail Agro's blog."
        />
        <Meta
          property="og:url"
          content="https://rashailagro.in/blog/all-blogs/"
        />
        <Meta property="og:type" content="website" />
        <Meta
          name="twitter:title"
          content="Rashail Agro Blog: Your Resource for Precision Farming"
        />
        <Meta
          name="twitter:description"
          content="Gain insights on precision farming, IoT, and agricultural technology from Rashail Agro's blog."
        />
        <Meta
          property="twitter:url"
          content="https://rashailagro.in/blog/all-blogs/"
        />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div className="row Termconditionbackground d-flex align-items-center">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h1 className="mt-5  border_new">
            <span className="privacy_heading ">All</span>
            <span className="policy_heading"> Blogs</span>
          </h1>
        </div>
      </div>
      <div className="row mt-5 mb-2 justify-content-center">
        <div className="d-flex justify-content-center">
          <div className="col-md-10 text-center d-flex">
            {loading ? (
              <div className="loading-container">
                <img
                  src={loading_image}
                  alt="Loading"
                  className="loading-image"
                  width={200}
                  height={200}
                />
              </div>
            ) : (
              <div className="row">
                {Allblog &&
                  Allblog.map((data, index) => (
                    <div key={index} className="col-md-4 col-6 mb-3">
                      <div className="testimonial-slide">
                        <div
                          className="card custom-card-style_blog"
                          style={{ width: "20rem" }}
                        >
                          <div className="card_blog_first">
                            <img
                              src={data.image}
                              alt={`blog-image-${index}`}
                              className="img-fluid all-blog_img"
                              style={{ width: "100%", objectFit: "cover" }}
                            />
                            <div className="card-body">
                              <h2 className="card-title card_home_heading text-start">
                                {data.title.length > 30
                                  ? `${data.title.substring(0, 30)}...`
                                  : data.title}
                                {data.title.length > 30}
                              </h2>
                              {data.content && (
                                <p
                                  className="card-text faq_answer text-start"
                                  dangerouslySetInnerHTML={{
                                    __html: truncateHtml(data.content, 40),
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <span
                            className={`readmore_blog text-center mt-2 ${
                              hoveredCard === index ? "show-arrow" : ""
                            }`}
                            onMouseEnter={() => handleHoverExplore(index)}
                            onMouseLeave={() => handleHoverExplore(null)}
                            onClick={() => {
                              toComponentB(data);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {hoveredCard === index ? (
                              <>
                                Full Article on {data.platform}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </>
                            ) : (
                              <>Read Full Story</>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="emptydiv" style={{ height: "200px" }}></div>
    </div>
  );
};

export default Allblogs;
