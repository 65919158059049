import React from "react";
import { Helmet } from "react-helmet";
import { Title, Meta, HeadProvider, Link } from "react-head";

const Termscondition = () => {
  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Rashail Agro Terms and Conditions</title>
                <meta name="description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <base target="_blank" href="https://rashailagro.in/terms-condition/" />
                <link rel="canonical" href="https://rashailagro.in/terms-condition/" />
                <meta name="keywords" content="rashail agro, user data, farmers data" />
                <meta property="og:title" content="Rashail Agro Terms and Conditions" />
                <meta property="og:description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <meta property="og:url" content="https://rashailagro.in/terms-condition/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro Terms and Conditions" />
                <meta name="twitter:description" content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security." />
                <meta property="twitter:url" content="https://rashailagro.in/terms-condition/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      <HeadProvider>
        <Title>Rashail Agro Terms and Conditions</Title>
        <Meta
          name="description"
          content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security."
        />
        <base target="_blank" href="https://rashailagro.in/terms-condition/" />
        <Link
          rel="canonical"
          content="https://rashailagro.in/terms-condition/"
        />
        <Meta name="keywords" content="rashail agro, user data, farmers data" />
        <Meta property="og:title" content="Rashail Agro Terms and Conditions" />
        <Meta
          property="og:description"
          content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security."
        />
        <Meta
          property="og:url"
          content="https://rashailagro.in/terms-condition/"
        />
        <Meta property="og:type" content="website" />
        <Meta
          name="twitter:title"
          content="Rashail Agro Terms and Conditions"
        />
        <Meta
          name="twitter:description"
          content="Review Rashail Agro's terms and conditions covering smart farming, precision agriculture, and their commitment to data security."
        />
        <Meta
          property="twitter:url"
          content="https://rashailagro.in/terms-condition/"
        />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div className="row Termconditionbackground d-flex align-items-center">
        <div className="col-md-11 col-sm-12  mx-auto">
          <h1 className="mt-5 ">
            <span className="privacy_heading">Terms</span>
            <span className="policy_heading"> & Conditions</span>
          </h1>
          {/* <p className="text-start border_new">Last Updated Jan 24, 2024</p> */}
          <p className="text-start border_new">
            Welcome to Rashail Agro, the online marketplace for agricultural
            products and services operated by Rashail Tech Labs Private Limited.
            By accessing and using our website and the Fasalam mobile app, you
            agree to comply with and be bound by the following Terms and
            Conditions. Please read these terms carefully before using our
            services.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <p className="paraheading_terms text-start mt-4 mb-5">
            Acceptance of Terms By accessing and using the services provided by
            Rashail Infotech and its website, you agree to comply with and be
            bound by these Terms and Conditions. If you do not agree with these
            terms, please refrain from using our services.
          </p>
          <span>
            <h2 className="mainheading_terms text-start">
              1.Acceptance of Terms
            </h2>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>By accessing and using Rashail
              Agro and the Fasalam app, you acknowledge that you have read,
              understood, and agreed to be bound by these Terms and Conditions.
              If you do not agree with any part of these terms, please do not
              use our services.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              2.Products and Services
            </h2>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>Rashail Agro offers a variety of
              agricultural products and services, including but not limited to
              Auto-Switch, GSM Motor Controllers, Motor Starters, Zatka
              Machines, and related accessories.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>The products and services
              provided by Rashail Agro are subject to availability. We reserve
              the right to modify or discontinue any product or service at any
              time without prior notice.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span> While we strive to provide
              accurate and up-to-date information about our products and
              services, Rashail Agro does not warrant the accuracy,
              completeness, or reliability of any information on our website or
              mobile app. The content provided is for general information
              purposes only.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              3.Ordering and Payments
            </h2>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>By placing an order on Rashail
              Agro or the Fasalam app, you confirm that you are legally capable
              of entering into a binding contract and that the information you
              provide is accurate and complete.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>All orders are subject to
              availability. We reserve the right to accept or decline any order
              at our discretion.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span> Payment for the products and
              services can be made through the available payment methods on our
              website and app. Prices are listed in the currency specified and
              are subject to change without notice.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              4.Shipping and Delivery
            </h2>

            <p className="paraheading_terms text-start">
              <span className="checkbox"></span> Rashail Agro strives to process
              and ship orders promptly, but we cannot guarantee specific
              delivery times or dates. Shipping times may vary depending on the
              destination and other factors beyond our control.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>Shipping costs, if applicable,
              will be calculated and added to your order at the time of
              checkout.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>Any customs duties, taxes, or
              additional charges imposed by the destination country are the
              responsibility of the customer.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              5.Returns and Refunds
            </h2>
            <h3 className="mainheading_terms text-start text-margin">
              1.Returns
            </h3>

            <p className="paraheading_terms text-start">
              <span className="mainheading_terms text-margin">
                1.1 Eligibility :{" "}
              </span>
              We accept returns and exchanges for products purchased directly
              from Rashail Agro within 14 days from the date of delivery,
              provided the following conditions are met:
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>The product is in its original
              condition and packaging, with all accessories and manuals
              included.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>The product must not have been
              damaged, altered, or used in any way that affects its resale
              value.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>Proof of purchase (order number,
              receipt, or invoice) is required for all returns and exchanges.
            </p>
            <p className="paraheading_terms text-start">
              <span className="mainheading_terms text-margin">
                1.2 Return Process :{" "}
              </span>
              To initiate a return or exchange, please follow these steps:
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>Contact our Customer Support
              team at rashailagro@gmail.com to request a return. Once you
              receive confirmation, securely package the product along with a
              copy of the original purchase receipt clearly marked on the
              package. Ship the product to the address provided by our Customer
              Support team.
            </p>
            <p className="paraheading_terms text-start">
              <span className="mainheading_terms text-margin">
                1.3 Return Shipping :{" "}
              </span>
              Customers are responsible for the return shipping costs unless the
              return is due to a manufacturing defect or an error made by
              Rashail Agro. We recommend using a trackable shipping method to
              ensure the safe and timely delivery of the returned product.
            </p>
            <p className="paraheading_terms text-start">
              <span className="mainheading_terms text-margin">
                1.4 Inspection and Processing :{" "}
              </span>
              Upon receiving the returned product, our team will inspect it to
              ensure it meets the eligibility criteria mentioned in section 1.1.
              If the product is approved for return, we will process the refund
              or exchange within 5 business days. Refunds will be issued to the
              original payment method used for the purchase.
            </p>
            {/* Refunds Begin */}
            <h3
              className="mainheading_terms text-start text-margin"
              style={{ marginTop: "10px" }}
            >
              2.Refunds
            </h3>

            <p className="paraheading_terms text-start">
              <span className="mainheading_terms text-margin">
                2.1 Eligibility :{" "}
              </span>
              Customers may request a refund for products that meet the
              eligibility criteria mentioned in section
            </p>

            <p className="paraheading_terms text-start">
              <span className="mainheading_terms text-margin">
                2.2 Refund Process :{" "}
              </span>
              To request a refund, please follow these steps: - Contact our
              Customer Support team at rashailagro@gmail.com to initiate the
              refund process. Provide the order number and a detailed reason for
              the refund request.
            </p>

            <p className="paraheading_terms text-start">
              <span className="mainheading_terms text-margin">
                2.3 Refund Amount :{" "}
              </span>
              The refund amount will be equal to the purchase price of the
              returned product minus any applicable restocking fees, return
              shipping costs (if applicable), or discounts applied during the
              purchase.
            </p>

            {/* Refunds End */}
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              6.Non-Refundable Items
            </h2>
            <p className="paraheading_terms text-start">
              The following items are non-refundable:
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>Products damaged due to customer
              misuse or negligence.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>Custom-made or personalized
              products.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              7.Intellectual Property
            </h2>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>All content, including but not
              limited to text, graphics, logos, images, videos, and software, on
              Rashail Agro and the Fasalam app is the property of Rashail Tech
              Labs Private Limited and is protected by applicable copyright and
              intellectual property laws.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>You may not reproduce, modify,
              distribute, or use any content from our website or app without
              prior written permission from Rashail Tech Labs Private Limited.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              9.Limitation of Liability
            </h2>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>To the fullest extent permitted
              by law, Rashail Tech Labs Private Limited, including its
              directors, employees, agents, and affiliates, shall not be liable
              for any direct, indirect, incidental, special, or consequential
              damages arising out of or in connection with the use of our
              website, app, products, or services.
            </p>
            <p className="paraheading_terms text-start">
              <span className="checkbox"></span>In no event shall our total
              liability exceed the amount paid by you for the specific product
              or service giving rise to the claim.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">8.Indemnification</h2>
            <p className="paraheading_terms mb-5 text-start">
              <span className="checkbox"></span>You agree to indemnify and hold
              harmless Rashail Tech Labs Private Limited and its directors,
              employees, agents, and affiliates from any claims, liabilities,
              damages, losses, and expenses, including legal fees, arising out
              of or in connection with your use of our website, app, products,
              or services.
            </p>
          </span>
        </div>
      </div>
      <div className="emptydiv" style={{ height: "150px" }}></div>
    </div>
  );
};

export default Termscondition;
