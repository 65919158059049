import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { postUrl } from "../Constants/urls";
import { Helmet } from "react-helmet";


const Post = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${postUrl}${slug}`);
                setPost(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [slug]);

    if (!post) return <div>Loading...</div>;

    const renderMedia = () => {
        switch (post.file_type) {
            case 'mp4':
                return (
                    <video className="img-fluid rounded" controls style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}>
                        <source src={post.image} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'pdf':
                return (
                    <iframe
                        src={post.image}
                        className="img-fluid rounded"
                        style={{ height: '600px', width: '100%' }}
                        title="PDF Viewer"
                    ></iframe>
                );
            default:
                return (
                    <img src={post.image} alt="Post" className="img-fluid rounded" style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />
                );
        }
    };

    // Function to convert newlines to <br /> tags
    const formatText = (text) => {
        if (!text) return '';
        return text.split(/\r\n|\r|\n/).map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="container mt-5">
            <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.description} />
            </Helmet>
            <div className="row justify-content-center">
                <div className="col-lg-6 mb-4 d-flex flex-column align-items-center bg-light p-4 rounded">
                    <div className="d-flex align-items justify-content-start mb-3 w-100" style={{ justifyContent: "center" }}>
                        <img
                            src={post.user_image || '/default-user.png'}
                            alt="User"
                            className="rounded-circle mb-3 mb-lg-0"
                            width="50"
                            height="50"
                        />
                        <div className="ms-lg-3">
                            <h5 className="mb-1">{post.user_name}</h5>
                            <p className="text-muted mb-1">{new Date(post.date).toLocaleDateString()}</p>
                            <p className="text-muted mb-4">{post.location}</p>
                        </div>
                    </div>
                    {renderMedia()}
                </div>
                <div className="col-lg-6 d-flex flex-column" style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <div className="bg-light p-4 rounded flex-grow-1">
                        <h3 className="mb-3">{post.title}</h3>
                        <p>{formatText(post.description)}</p>
                    </div>
                </div>
            </div>
            <div className="emptydiv" style={{ height: "200px" }}></div> {/* keep this do not remove */}
        </div>
    );
};

export default Post;
