import React, { useEffect } from "react";
import gsmmotor from "../Images/Home/gsm motor.png";
import { Helmet } from "react-helmet";
import Demoform from "../Components/Demoform";
import { Card, Modal, Button } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useState } from "react";
import Iotproduct from "../Components/Iotproduct";
import { Title, Meta, HeadProvider, Link } from "react-head";
import Buynowform from "../Components/Buynowform";
import { Prices, Shipping } from "../Constants/Homedata";
import { useLocation } from "react-router-dom";
import ReactImageZoom from "react-image-zoom";
import axios from "axios";
import { producturl, serverToken } from "../Constants/urls";
import { add, remove } from "../Redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactImageMagnify from "react-image-magnify";
import iosqr from "../Images/Home/iosqr.webp";
import qrforandroid from "../Images/Home/qr_forandroid.png";

const Gsmmotorcontroller = () => {
  // Data
  const location = useLocation();
  const url = window.location.href;
  const url1 = window.location.origin;
  const parturl = url.replace(url1, "").replace("/shop/", "");
  let slug = parturl.replace("/", "");
  slug = slug.includes("?") ? slug.split("?")[0] : slug;
  const [showModal, setShowModal] = useState(false);
  const products = useSelector((state) => state.cart);

  const [product, setproduct] = useState();
  const dispatch = useDispatch();
  const [showModal1, setShowModal1] = useState(false);
  const [productName, setProductName] = useState(product?.product_name || "");
  const [Price, setPrice] = useState(product?.product_price || 0);

  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalOpen1 = () => {
    setShowModal1(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowApplicationForm(false);
  };
  const handleModalClose1 = () => {
    setShowModal1(false);
  };

  const handleAdd = (product) => {
    dispatch(add(product));
  };

  const handleRemove = (product_id) => {
    dispatch(remove(product_id));
  };

  const isInCart = (productId) => {
    return products.some((item) => item.product_id === productId);
  };

  const fetchData = async (page = 1) => {
    try {
      //   setLoading(true);
      const token = serverToken;
      const response = await axios.get(`${producturl}?slug=${slug}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setproduct(response.data.results[0]);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    product_name: productName,
    // description: '',
    address: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    phone_number: "",
    email: "",
    // product_name: '',
    // description: '',
    address: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Reset error message when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation
    let formIsValid = true;
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === "") {
        formIsValid = false;
        newErrors[key] = `${getFieldName(key)} is required`;
      }
    });

    if (!formIsValid) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // console.log('Form submitted successfully');
        setFormData({
          name: "",
          phone_number: "",
          email: "",
          address: "",
        });
        handleModalClose();
      } else {
        console.error("Error submitting form");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getFieldName = (fieldName) => {
    return fieldName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const schema = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: "Rashail - GSM Motor Controller 2G/4G",
    image:
      "https://rashailagro.in/static/media/gsm%20motor.3af9bc88abf6e425f1b1.png",
    description:
      "Introducing Rashail Agro&#39;s<STRONG> GSM Motor Controller</STRONG> 2G/4G – the ultimate solution for motor control in irrigation and submersible applications. Say goodbye to manual and hello to technology; This <STRONG>mobile pump controller </STRONG> gives you the power to manage your motors from anywhere in the world, via your mobile phone.<BR/>But it doesn&#39;t stop there. Our<STRONG> GSM Motor Controller</STRONG>  goes the extra mile with security features too. Protect your motors from coil burning with advanced Phase Fault and Dry Run Prevention Features.<BR/>Increase the life of your motors and add convenience, peace of mind to the mix. Rashail Agro&#39;s<STRONG>  GSM Motor Controller</STRONG> is a worthy investment and your gateway to smart farming.",
    offers: {
      "@type": "Offer",
      price: 3999.0,
      priceCurrency: "INR",
    },
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleThumbnailHover = (image) => {
    setSelectedImage(image);
  };

  const [technicalDetails, setTechnicalDetails] = useState([]);
  const [additionalDetails, setAdditionalDetails] = useState([]);

  useEffect(() => {
    // Fetch and set technical details from API response
    if (product && product.extra_data && product.extra_data.technical_details) {
      setTechnicalDetails(product.extra_data.technical_details);
    }
    if (
      product &&
      product.extra_data &&
      product.extra_data.additional_details
    ) {
      setAdditionalDetails(product.extra_data.additional_details);
    }
  }, [product]);

  useEffect(() => {
    if (product && product.product_images.length > 0) {
      setSelectedImage(product.product_images[0]);
    }
    if (product) {
      setProductName(product.product_name); // Set productName state
      setPrice(Math.round(product.product_price -
        product.product_price *
        (product.product_discount /
          100))); // Set Price state
    }
  }, [product]);

  const [smallImageDimensions, setSmallImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const img = new Image();
    img.src = selectedImage;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const maxWidth = 400;
      const maxHeight = 400;
      let width = maxWidth;
      let height = maxHeight;

      if (aspectRatio > maxWidth / maxHeight) {
        height = maxWidth / aspectRatio;
      } else {
        width = maxHeight * aspectRatio;
      }

      setSmallImageDimensions({ width, height });
    };
  }, [selectedImage]);

  const handleDownloadClick = () => {
    if (navigator.userAgentData.mobile) {
      if (navigator.userAgentData.platform.includes("Android")) {
        window.open(
          "https://play.google.com/store/apps/details?id=com.rashailagro.fasalam",
          "_blank"
        );
      } else {
        window.open(
          "https://apps.apple.com/in/app/fasalam/id6478108954",
          "_blank"
        );
      }
    } else {
      handleModalOpen1();
    }
  };

  const handleDownloadClick_android = () => {
    window.open(
      "https://apkpure.com/fasalam/com.rashailagro.fasalam",
      "_blank"
    );
  };

  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Rashail's Mobile Starter: Automation for Efficient Farming</title>
                <meta name="description" content="Control irrigation and other farm equipment remotely with Rashail Agro's GSM Motor Controller." />
                <base target="_blank" href="https://rashailagro.in/products/gsm-motor-controller/" />
                <link rel="canonical" href="https://rashailagro.in/products/gsm-motor-controller/" />
                <meta name="keywords" content="motor mobile, gsm motor, mobile starter, mobile motor starter, mobile auto starter, gsm based motor control, gsm mobile starter, mobile starter for 3 phase motor, gsm controlled motor, gsm motor controller, mobile auto switch" />
                <meta property="og:title" content="Rashail's Mobile Starter: Automation for Efficient Farming" />
                <meta property="og:description" content="Control irrigation and other farm equipment remotely with Rashail Agro's GSM Motor Controller." />
                <meta property="og:url" content="https://rashailagro.in/products/gsm-motor-controller/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail's Mobile Starter: Automation for Efficient Farming" />
                <meta name="twitter:description" content="Control irrigation and other farm equipment remotely with Rashail Agro's GSM Motor Controller." />
                <meta property="twitter:url" content="https://rashailagro.in/products/gsm-motor-controller/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      <HeadProvider>
        <Title>{product?.meta_title}</Title>
        <Meta name="description" content={product?.meta_header} />
        <base target="_blank" href={`https://rashailagro.in/shop/${slug}/`} />
        <Link
          rel="canonical"
          content={`https://rashailagro.in/shop/${slug}/`}
        />
        <Meta
          name="keywords"
          content="motor mobile, gsm motor, mobile starter, mobile motor starter, mobile auto starter, gsm based motor control, gsm mobile starter, mobile starter for 3 phase motor, gsm controlled motor, gsm motor controller, mobile auto switch"
        />
        <Meta property="og:title" content={product?.meta_title} />
        <Meta property="og:description" content={product?.meta_header} />
        <Meta
          property="og:url"
          content={`https://rashailagro.in/shop/${slug}/`}
        />
        <Meta property="og:type" content="website" />
        <Meta name="twitter:title" content={product?.meta_title} />
        <Meta name="twitter:description" content={product?.meta_header} />
        <Meta
          property="twitter:url"
          content={`https://rashailagro.in/shop/${slug}/`}
        />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div className="row mt-md-5 justify-content-center">
        <div className="col-md-12 col-lg-6 d-flex justify-content-center">
          <div className="col-md-12 productimg-productdetails col-lg-6  col-12 text-center">
            <div
              className="text-center selectedimage_resp mx-auto"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "400px",
                position: "relative",
              }}
            >
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "Wristwatch by Ted Baker London",
                    className: "Wristwatch by Ted Baker London",
                    isFluidWidth: false, // Set to false to fix the width
                    width: smallImageDimensions.width, // Set the width of the image
                    height: smallImageDimensions.height,
                    src: selectedImage,
                  },
                  largeImage: {
                    src: selectedImage,
                    width: 1200,
                    height: 1200,
                  },
                  enlargedImageContainerDimensions: {
                    width: "200%",
                    height: "150%",
                  },
                  enlargedImageContainerStyle: {
                    backgroundColor: "white",
                  },
                }}
              />
            </div>
            <div
              className="image-container-productdetails d-flex mt-2 d-none d-md-block"
              style={{
                maxWidth: "400px",
                width: "350px",
                overflowX: "scroll",
                "-ms-overflow-style": "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
              >
                {product &&
                  product?.product_images.map((image, index) => (
                    <div
                      key={index}
                      className="mx-2"
                      style={{ minWidth: "120px", maxWidth: "120px" }}
                    >
                      <img
                        src={image}
                        alt={`Thumbnail ${index}`}
                        className="img-fluid image-thumbnail"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "70px",
                          objectFit: "contain",
                        }}
                        onMouseEnter={() => handleThumbnailHover(image)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* for mobile view ........... */}
        <div className="col-md-12 col-lg-6 d-md-none d-flex justify-content-center">
          <div
            className="image-container-productdetails mt-2"
            style={{
              maxWidth: "400px",
              width: "100%",
              overflowX: "scroll",
              "-ms-overflow-style": "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              {product &&
                product?.product_images.map((image, index) => (
                  <div
                    key={index}
                    className="mx-2"
                    style={{ minWidth: "120px", maxWidth: "120px" }}
                  >
                    <img
                      src={image}
                      alt={`Thumbnail ${index}`}
                      className="img-fluid image-thumbnail"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "70px",
                        objectFit: "contain",
                      }}
                      onMouseEnter={() => handleThumbnailHover(image)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 text-start mt-4 mt-md-4">
          <div className="text-start">
            <div>
              {product?.is_top_selling ? (
                <span className="percent_off" style={{ cursor: "pointer" }}>
                  Top selling
                </span>
              ) : product?.on_sale ? (
                <span
                  className="percent_off_onsale"
                  style={{ cursor: "pointer" }}
                >
                  On sale
                </span>
              ) : (
                ""
              )}
            </div>
            <h1 className="productdetailsheading text-start mt-2">
              {product?.product_name}
            </h1>
            <div className="productdetailspara mt-4 mb-3 text-start">
              <p className="description_productdetails">
                {product?.product_description
                  .split("\n")
                  .map((paragraph, index) => (
                    <React.Fragment key={index}>
                      {paragraph}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              {product?.is_enquiry_product ? (
                <div className="">
                  <strong style={{ color: "#2FB95D" }}>Demo Product</strong>
                </div>
              ) : (
                <>
                  {product?.product_discount > 0 && (
                    <span
                      style={{ color: "#2FB95D" }}
                    >{`${product?.product_discount}% OFF`}</span>
                  )}
                  <div>
                    <span className="fullprice_product">
                      <small style={{ textDecoration: "line-through" }}>
                        ₹
                        {product?.product_price}
                      </small>
                    </span>
                    <span className="actualprice_product mx-2">
                      ₹{Math.round(product?.product_price -
                        product?.product_price *
                        (product?.product_discount /
                          100))}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row   justify-content-center">
        <div className="row justify-content-center ">
          <div className="col-md-10 justify-content-center">
            {product?.is_enquiry_product ? (
              <div
                className="col-md-3 col-6 readmore_blog text-center custom-span  mx-auto"
                onClick={handleModalOpen1}
                style={{ cursor: "pointer" }}
              >
                Request a Demo
              </div>
            ) : (
              <>
                <div className="row justify-content-end">
                  <div className="col-md-6 d-flex">
                    <div
                      className="col-md-6 col-6 readmore_blog text-center custom-span  mx-auto"
                      onClick={handleModalOpen}
                      style={{ cursor: "pointer" }}
                    >
                      Buy Now
                    </div>
                    <div
                      className="col-md-6 col-6  text-center custom-span  mx-auto"
                      style={{ cursor: "pointer" }}
                    >
                      {isInCart(product?.product_id) ? (
                        <button
                          className="btn buttonfunctionaity_productdetails mx-1 "
                          onClick={() => handleRemove(product.product_id)}
                        >
                          Remove from Cart
                        </button>
                      ) : (
                        <button
                          className="btn buttonfunctionaity_productdetails mx-1"
                          onClick={() => handleAdd(product)}
                        >
                          Add to Cart
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-10 col-sm-10 text-center mt-5">
          <span>
            <h2 className="productdetailsheading text-start">
              {product?.product_name} Features
            </h2>
            <div className="productdetailspara text-start mt-4">
              {product?.meta_data ? (
                <ul>
                  {product.meta_data.split("\n").map((sentence, index) => (
                    <li key={index}>{sentence}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          </span>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-10 justify-content-center">
            {product?.is_enquiry_product ? (
              <div
                className="col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto"
                onClick={handleModalOpen1}
                style={{ cursor: "pointer" }}
              >
                Request a Demo
              </div>
            ) : (
              <div
                className="col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto"
                onClick={handleModalOpen}
                style={{ cursor: "pointer" }}
              >
                Buy Now
              </div>
            )}
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-10 text-center justify-content-center">
            <div className="row text-center mt-3">
              <div className="col-md-6 mb-2">
                <div
                  className="card card_iotproductdetails"
                  style={{ padding: "10px", textAlign: "left" }}
                >
                  <div
                    className="card-body"
                    style={{ paddingLeft: "20px", textAlign: "left" }}
                  >
                    <h3 className="card-title mt-3 productdetailsheading text-center">
                      Technical Details
                    </h3>
                    <div className="mt-4">
                      {technicalDetails.map((detail, index) => (
                        <div className="row d-flex  mb-2" key={index}>
                          <div className="col-md-12 d-flex">
                            <div className="col-md-6 col-6">
                              <span className="technicaldetailshead">
                                {Object.keys(detail)[0]}
                              </span>
                            </div>
                            <div className="col-md-6 col-6 mx-2">
                              <span className="technicaldetailssubhead">
                                {Object.values(detail)[0]}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 mb-2">
                <div
                  className="card card_iotproductdetails"
                  style={{ padding: "10px", textAlign: "left" }}
                >
                  <div
                    className="card-body"
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "10px",
                      textAlign: "left",
                    }}
                  >
                    <h3 className="card-title mt-3 productdetailsheading text-center">
                      Additional Info
                    </h3>
                    <div className="mt-4">
                      {additionalDetails.map((detail, index) => (
                        <div className="row d-flex  mb-2" key={index}>
                          <div className="col-md-12 d-flex">
                            <div className="col-md-6 col-6">
                              <span className="technicaldetailshead">
                                {Object.keys(detail)[0]}
                              </span>
                            </div>
                            <div className="col-md-6 col-6 mx-2">
                              <span className="technicaldetailssubhead">
                                {Object.values(detail)[0]}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col-md-10 justify-content-center">
            {product?.is_enquiry_product ? (
              <div
                className="col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto"
                onClick={handleModalOpen1}
                style={{ cursor: "pointer" }}
              >
                Request a Demo
              </div>
            ) : (
              <div
                className="col-md-3 col-6 readmore_blog text-center custom-span mt-2 mx-auto"
                onClick={handleModalOpen}
                style={{ cursor: "pointer" }}
              >
                Buy Now
              </div>
            )}
          </div>
        </div>
        {/* For Hardware Section Begin */}
        <div className="row middle_down_farming justify-content-center text-center mt-5 mb-5 position-relative">
          <div className="col-md-5 ">
            <h2 className="mt-3 text-center text-md-start download_farming_home">
              Download your Free App Now!
            </h2>
            <p className="text-center text-md-start enhance_home">
              Fasalam is free and always will be. Download your farming
              companion and discover how innovation is reshaping farming.
            </p>
          </div>
          <div className="col-md-5 d-flex justify-content-center">
            <div
              className="card mx-2 "
              style={{
                width: "18rem",
                position: "relative",
                borderRadius: "15px",
              }}
            >
              <div className="card-body">
                <h3 className="card-title forios_heading text-start mx-md-3 mt-3 mb-3">
                  For Hardware
                </h3>
                <button
                  className="btn buttonfunctionaity_farming btn-blocks mb-2"
                  onClick={handleDownloadClick_android}
                >
                  Download app
                </button>
                <div className="mt-md-4 mx-md-3 text-start ">
                  <img
                    src={qrforandroid}
                    alt="fasalam-mobile-app"
                    className="img-fluid"
                    width={70}
                    height={70}
                  />
                </div>
                <div
                  className="svg-container"
                  style={{ position: "absolute", bottom: -16, right: -16 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="90"
                    height="90"
                    viewBox="0 0 90 90"
                    fill="none"
                  >
                    <g transform="translate(30, 30)">
                      <circle cx="22" cy="26" r="35" fill="#2FB95D" />
                      <g clip-path="url(#clip0_1617_5839)">
                        <path
                          d="M6.66272 15.548C5.83593 15.548 5.13341 15.8362 4.55669 16.4135C3.97997 16.9905 3.69141 17.6827 3.69141 18.4903V30.8949C3.69141 31.7213 3.97987 32.4232 4.55669 33.0002C5.13341 33.5773 5.83552 33.8653 6.66241 33.8653C7.48951 33.8653 8.18657 33.5772 8.75379 33.0002C9.32142 32.4232 9.60503 31.7213 9.60503 30.8949V18.4903C9.60503 17.6824 9.31637 16.9902 8.73965 16.4135C8.16282 15.8365 7.47021 15.548 6.66272 15.548Z"
                          fill="#FBFBFB"
                        />
                        <path
                          d="M10.7598 35.3083C10.7598 36.1927 11.0675 36.9427 11.6828 37.5583C12.2983 38.1731 13.0481 38.4807 13.9329 38.4807H16.0677L16.0968 45.029C16.0968 45.8561 16.3852 46.5576 16.9621 47.1347C17.5388 47.7117 18.2313 48.0001 19.0389 48.0001C19.8662 48.0001 20.5679 47.7117 21.1449 47.1347C21.7219 46.5576 22.0102 45.8561 22.0102 45.029V38.4807H25.9908V45.029C25.9908 45.8561 26.2791 46.5576 26.8559 47.1347C27.4333 47.7117 28.1352 48.0001 28.9623 48.0001C29.7887 48.0001 30.4906 47.7117 31.0673 47.1347C31.644 46.5576 31.9321 45.8561 31.9321 45.029V38.4807H34.0959C34.9608 38.4807 35.7014 38.1737 36.3167 37.5583C36.9322 36.9427 37.24 36.1927 37.24 35.3083V16.0961H10.7598V35.3083Z"
                          fill="#FBFBFB"
                        />
                        <path
                          d="M30.5477 4.41346L32.5953 0.634356C32.7302 0.384389 32.6817 0.192115 32.4514 0.057634C32.201 -0.0578519 32.0088 -0.000159481 31.8747 0.230812L29.7973 4.03841C27.9704 3.23072 26.0382 2.82687 23.9994 2.82687C21.9611 2.82687 20.0282 3.23072 18.2012 4.03841L16.1242 0.230913C15.9901 -5.84507e-05 15.7975 -0.0577509 15.5475 0.057735C15.3166 0.192216 15.2689 0.38449 15.4034 0.634457L17.4516 4.41346C15.3743 5.47102 13.721 6.94708 12.4898 8.84133C11.2591 10.7357 10.6436 12.808 10.6436 15.0577H37.3271C37.3271 12.808 36.7116 10.7356 35.4808 8.84133C34.2495 6.94708 32.6061 5.47092 30.5477 4.41346ZM18.706 9.90849C18.4851 10.1298 18.2208 10.2403 17.913 10.2403C17.6052 10.2403 17.3458 10.1299 17.134 9.90849C16.9227 9.68752 16.8167 9.42301 16.8167 9.11535C16.8167 8.80759 16.9227 8.54307 17.134 8.3218C17.3455 8.10052 17.6053 7.99019 17.913 7.99019C18.2204 7.99019 18.4848 8.10042 18.706 8.3218C18.9273 8.54307 19.0379 8.80759 19.0379 9.11535C19.0379 9.42311 18.9273 9.68722 18.706 9.90849ZM30.8654 9.90849C30.6534 10.1298 30.3938 10.2403 30.0861 10.2403C29.7783 10.2403 29.5145 10.1299 29.2932 9.90849C29.0719 9.68752 28.9617 9.42301 28.9617 9.11535C28.9617 8.80759 29.0719 8.54307 29.2932 8.3218C29.5144 8.10052 29.7783 7.99019 30.0861 7.99019C30.3938 7.99019 30.6534 8.10042 30.8654 8.3218C31.0764 8.54307 31.1826 8.80759 31.1826 9.11535C31.1826 9.42311 31.0767 9.68722 30.8654 9.90849Z"
                          fill="#FBFBFB"
                        />
                        <path
                          d="M43.4424 16.3989C42.8656 15.8317 42.1637 15.548 41.3366 15.548C40.5293 15.548 39.8369 15.8317 39.2602 16.3989C38.6832 16.9663 38.3945 17.6636 38.3945 18.4903V30.8949C38.3945 31.7213 38.6832 32.4232 39.2602 33.0002C39.8369 33.5773 40.5293 33.8653 41.3366 33.8653C42.1637 33.8653 42.8656 33.5772 43.4424 33.0002C44.0198 32.4232 44.3081 31.7213 44.3081 30.8949V18.4903C44.3081 17.6635 44.0198 16.9662 43.4424 16.3989Z"
                          fill="#FBFBFB"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* For Hardware Section End */}
        <Modal
          size="lg"
          show={showModal}
          onHide={handleModalClose}
          //  scrollable
          dialogClassName="custom-modal"
        >
          <Modal.Header
            className="mt-2"
            closeButton
            style={{ borderBottom: "none" }}
          >
            <Modal.Title className="text-center">Checkout</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="col-md-12 col-sm-12 text-center mx-auto">
              <div className="mt-3">
                <Buynowform
                  productName={productName}
                  Price={Price}
                  onSubmit={handleSubmit}
                  setShowModal={setShowModal}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showModal1}
          onHide={handleModalClose1}
        //  scrollable
        >
          <Modal.Header
            className="mt-2"
            closeButton
            style={{ borderBottom: "none" }}
          >
            {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="col-md-10 col-sm-10 text-center mx-auto">
              <div className="mt-3">
                <Demoform
                  closeModal={handleModalClose}
                  closeModal1={handleModalClose1}
                  productName={productName}
                  onSubmit={handleSubmit}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Iotproduct />
        <div className="emptydiv" style={{ height: "200px" }}></div>
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </div>
  );
};

export default Gsmmotorcontroller;
