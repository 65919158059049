import GalleryComponent from "../Components/GalleryComponent";
import TabView from "../Components/TabView";
import img1 from "../Images/Shop/738a6df4-e51a-4b19-b629-0334dba8a8e5.jpeg";
import img2 from "../Images/Shop/IMG20241116181919.jpg";
import img3 from "../Images/Shop/e75b73b0-ae46-40a9-8c57-6acc0b752b20.jpeg";
import img4 from "../Images/Shop/f4384f77-2caa-4e43-8d9f-5fa36628f4cf.jpeg";
import farmerImg1 from "../Images/farmer/1-Rashail-Farmer-Review..jpeg";
import farmerImg2 from "../Images/farmer/2-Rashail-Farmer-Review.jpeg";
import farmerImg3 from "../Images/farmer/20241110_50304pmByGPSMapCamera.jpg";
import farmerImg4 from "../Images/farmer/3.jpeg";
import farmerImg5 from "../Images/farmer/323681d1-b40e-4eb6-88fa-e4cf809fd8cd.jpeg";
import farmerImg6 from "../Images/farmer/4.jpeg";
import farmerImg7 from "../Images/farmer/5.jpeg";
import farmerImg8 from "../Images/farmer/6.jpeg";
import farmerImg9 from "../Images/farmer/IMG20241116160618.jpg";
import farmerImg10 from "../Images/farmer/IMG20241118190303.jpg";
import farmerImg11 from "../Images/farmer/IMG20241118193808.jpg";
import farmerImg12 from "../Images/farmer/IMG20241119184613.jpg";
import farmerImg13 from "../Images/farmer/IMG20241119184656.jpg";
import farmerImg14 from "../Images/farmer/IMG20241119191302.jpg";
import farmerImg15 from "../Images/farmer/a8788c1a-8e34-42af-81b9-539433676396.jpeg";
import farmerImg16 from "../Images/farmer/e9fee72d-1026-433a-a978-50af9621041b.jpeg";
import farmerImg17 from "../Images/farmer/f2f96933-4ab0-4348-98f7-16699a300110.jpeg";
import farmerImg18 from "../Images/farmer/fc23c6c8-0a9a-4f69-8f7b-6815a904df00.jpeg";

const Gallery = () => {

    return (
        <div className="container mt-5">
            <div className="row mt-5 mb-2 justify-content-center">
                <div class="col-md-8 text-center">
                    <h3>
                        {" "}
                        <span className="line_heading"></span>Explore</h3>
                    <h2 className="heading_home">
                        Our{" "}
                        <span className="heading_home_color">Devices</span>
                    </h2>
                </div>
                <TabView tabs={[
                    {
                        key: 'devices',
                        title: 'Devices',
                        content: (
                            <div className="text-center">
                                <iframe
                                    src="https://www.google.com/maps/d/u/0/embed?mid=1fjR06nnAdJimT9YvAYJrsl9-hWKoL3A&ehbc=2E312F"
                                    width="100%"
                                    height="480"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    title="Google Map Embed"
                                ></iframe>
                            </div>
                        ),
                    },
                    {
                        key: 'shops',
                        title: 'Shops',
                        content: (
                            <div className="text-center">
                                <iframe
                                    src="https://www.google.com/maps/d/u/0/embed?mid=1fjR06nnAdJimT9YvAYJrsl9-hWKoL3A&ehbc=2E312F"
                                    width="100%"
                                    height="480"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    title="Google Map Embed"
                                ></iframe>
                            </div>
                        ),
                    },
                ]} />
                {/* <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1fjR06nnAdJimT9YvAYJrsl9-hWKoL3A&ehbc=2E312F" height="480"></iframe> */}
            </div>
            <div className="row mt-5 mb-2 justify-content-center">
                <div class="col-md-8 text-center">
                    <h3>
                        {" "}
                        <span className="line_heading"></span>Explore</h3>
                    <h2 className="heading_home">
                        OUR{" "}
                        <span className="heading_home_color">GALLERY</span>
                    </h2>
                </div>
                <TabView tabs={[
                    {
                        key: 'shops',
                        title: 'Shops',
                        content: (
                            <div className="mt-5">
                                <GalleryComponent images={[
                                    img1, img2, img3, img4,
                                ]} />
                            </div>
                        ),
                    },
                    {
                        key: 'farmers',
                        title: 'Farmers',
                        content: (
                            <div className="mt-5">
                                <GalleryComponent images={[
                                    farmerImg1,
                                    farmerImg2,
                                    farmerImg3,
                                    farmerImg4,
                                    farmerImg5,
                                    farmerImg6,
                                    farmerImg7,
                                    farmerImg8,
                                    farmerImg9,
                                    farmerImg10,
                                    farmerImg11,
                                    farmerImg12,
                                    farmerImg13,
                                    farmerImg14,
                                    farmerImg15,
                                    farmerImg16,
                                    farmerImg17,
                                    farmerImg18,
                                ]} />
                            </div>
                        ),
                    },
                ]} />
            </div>
            <div className="emptydiv" style={{ height: "200px" }}></div> {/* keep this do not remove */}
        </div>
    );
};

export default Gallery;
