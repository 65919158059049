import React from 'react';
import YouTube from 'react-youtube';

const YouTubePlayer = ({ videoId }) => {
    const opts = {
        height: '450vw', // 56.25% of the viewport width for a 16:9 aspect ratio
        width: '100%', // 100% of the parent container's width
        playerVars: {
            autoplay: 0,
        },
        playerStyle: {
            borderRadius: '30px',
        },
    };

    const mobileOpts = {
        width: '100%', // Adjust the width for mobile view
        playerVars: {
            autoplay: 0,
        },
        playerStyle: {
            borderRadius: '10px', // Adjust the border radius for mobile view
        },
    };

    

    return (
        <div className="largevideo" >
            {window.innerWidth > 500 ? (
                <YouTube videoId={videoId} opts={opts} />
            ) : (
                <YouTube videoId={videoId} opts={mobileOpts} />
            )}
        </div>
    );
};

export default YouTubePlayer;
