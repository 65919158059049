import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import Privacypolicy from "./Pages/Privacypolicy";
import ScrollToTop from "./Components/scroll";
import Termscondition from "./Pages/Termscondition";
import Blog from "./Pages/Blog";
import Aboutus from "./Pages/Aboutus";
import Features from "./Pages/Features";
import Gsmmotorcontroller from "./Pages/Gsmmotorcontroller";
import { Helmet } from "react-helmet";
import favicon from "../src/Images/blog/fasalamlogo.webp";
import Products from "./Pages/Products";
import Contactus from "./Pages/Contactus";
import Allblogs from "./Pages/Allblogs";
import PageNotFound from "./Pages/PageNotFound";
import Blogdetails from "./Pages/Blogdetails";
import Cart from "./Pages/Cart";
import Satelite from "./Pages/Satelite";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Redirect } from "react-router";
import { redirect } from "react-router-dom";
import Downloadfasalam from "./Pages/Downloadfasalam";
import Navigations from "./Pages/navigations";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Forum from "./Pages/Forum";
import Post from "./Pages/Post";
import Gallery from "./Pages/Gallery";

// import whatsupimg from '../src/Images/Footer/whatsapp_click_to_chat-removebg-preview.png'

// import 'react-toastify/dist/ReactToastify.css';

function App() {
  const handleWhatsAppUpdate = () => {
    const message =
      "Dear customer, we wanted to inform you about the latest updates on your order.";
    const formattedMessage = encodeURIComponent(message);
    const phoneNumber = 8602793619;
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${formattedMessage}`;
    window.open(whatsappURL, "_blank");
    window.dataLayer.push({ event: "button-click" });
  };

  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error(error);
      }
    };
    fetchIp();
  }, []);

  const customColor = "rgba(64, 195, 81, 1)";
  return (
    <Router>
      <div>
        {/* <Helmet>
          <title>Smart Farming Solutions by Rashail Agro</title>
          <meta name="description" content="Increase farm productivity with Rashail Agro's mobile app, IoT devices, and data-driven insights." />
          <base target="_blank" href="https://rashailagro.in/" />
          <link rel="canonical" href="https://rashailagro.in/" />
          <meta name="keywords" content="smart farming, precision farming, new technologies in agriculture, iot in agriculture, internet of things in agriculture, farming in india, farming app, farming and agriculture, farmer tools, farmer in agriculture, farm equipment, artifi
          cial intelligence in agriculture, ai in agriculture, agriculture technology, agriculture startups in india, agriculture in iot, agriculture in india, agriculture farming, agriculture equipment machine, agriculture equipment list, agriculture equipment, agriculture department, agriculture app, agriculture and technology, agricultural services, agricultural products, agricultural machinery, agriculture, agricalchar farm, agri tech, agri startups in india, crop management solution, iot smart agriculture" />
          <meta property="og:title" content="Smart Farming Solutions by Rashail Agro" />
          <meta property="og:description" content="Increase farm productivity with Rashail Agro's mobile app, IoT devices, and data-driven insights." />
          <meta property="og:url" content="https://rashailagro.in/" />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Smart Farming Solutions by Rashail Agro" />
          <meta name="twitter:description" content="Increase farm productivity with Rashail Agro's mobile app, IoT devices, and data-driven insights." />
          <meta property="twitter:url" content="https://rashailagro.in/" />
          <meta property="twitter:type" content="website" />
        </Helmet> */}
      </div>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/privacy-policy/" element={<Privacypolicy />} />
        <Route exact path="/terms-condition/" element={<Termscondition />} />
        <Route exact path="/blog/" element={<Blog />} />
        <Route exact path="/blog/:slug" element={<Blogdetails />} />
        <Route exact path="/about-us/" element={<Aboutus />} />
        <Route exact path="/features/" element={<Features />} />
        <Route exact path="/satellite/" element={<Satelite />} />
        <Route exact path="/shop/:slug/" element={<Gsmmotorcontroller />} />
        <Route exact path="/shop/" element={<Products />} />
        <Route exact path="/cart/" element={<Cart />} />
        <Route exact path="/contact-us/" element={<Contactus />} />
        <Route exact path="/blog/all-blogs/" element={<Allblogs />} />
        <Route exact path="/download-fasalam/" element={<Downloadfasalam />} />
        <Route exact path="/navigations/" element={<Navigations />} />
        <Route path="/forum/:slug" element={<Forum />} />
        <Route path="/community/:slug" element={<Post />} />
        <Route path="/gallery/" element={<Gallery />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ScrollToTop />
      <a
        href="javascript:void(0);"
        onClick={handleWhatsAppUpdate}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "1000",
          textDecoration: "none",
        }}
      >
        <div className="floatingBtn_whatsapp">
          <i
            className="fa fa-whatsapp footer-icon"
            aria-hidden="true"
            style={{ fontSize: "40px", cursor: "pointer", color: "#ffffff" }}
            onClick={handleWhatsAppUpdate}
          ></i>
        </div>
      </a>

      {/* <Box sx={{ '& > :not(style)': { m: 1 } }}>
     
      <Fab style={{ backgroundColor: customColor }} aria-label="add">
         <i
            className="fa fa-whatsapp footer-icon"
            aria-hidden="true"
            style={{ fontSize: '40px', cursor: "pointer", color: "#ffffff" }}
            onClick={handleWhatsAppUpdate}
          ></i>
      </Fab>
    </Box> */}
      <Footer />
    </Router>
  );
}

export default App;
