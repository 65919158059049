import React from 'react';

const Navigations = () => {
    const url = window.location.origin;
    return (
        <div className="navigations navigation-css">
            <h1>Navigations</h1>
            <h2>
                <a target="_blank" rel="noopener noreferrer" href={`${url}/`}>
                    <span style={{ color: 'rgb(74, 110, 224)' }}>Home</span>
                </a>
            </h2>
            <ul>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/features/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Fasalam App</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Shop</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/cart/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>Cart</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/about-us/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>About Us</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Blog</span>
                    </a>
                </li>
            </ul>
            <h2>
                <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/`}>
                    <span style={{ color: 'rgb(74, 110, 224)' }}>Shop</span>
                </a>
            </h2>
            <ul>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/iot-motor-starter/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>IoT Mobile Motor Controller</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/gsm-motor-controller-mobile-starter/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>GSM Motor Controller</span>
                    </a>
                    <span style={{ color: 'rgb(74, 110, 224)', textDecoration: 'underline' }}> 2G</span>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/gsm-motor-controller-mobile-starter-4g/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>GSM Motor Controller 4G</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/rapid-soil-tester/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Rapid Soil Tester</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/dol-motor-starter/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>DOL Motor Controller</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/solar-fence-energizer/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Solar Fence Energizer</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/farm-iot-data-logger/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Farm IoT Data Logger</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/solar-insect-trapper/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Solar Insect Trapper</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/phase-preventer-mini/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Phase Preventer Mini</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/phase-preventer-dol/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Phase Preventer DOL</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/farm-weather-station/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Farm Weather Station</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/iot-mushroom-data-logger/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>IoT Mushroom Data Logger</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/shop/iot-hydroponics-data-logger/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>IoT Hydroponics Data Logger</span>
                    </a>
                </li>
            </ul>
            <h2>
                <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/`}>
                    <span style={{ color: 'rgb(74, 110, 224)' }}>Blog</span>
                </a>
            </h2>
            <ul>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/how-5g-is-powering-autonomous-farm-equipment`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>How 5G is Powering Autonomous Farm Equipment</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/how-the-next-election-could-transform-indias-agritech-landscape/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>How the Next Election Could Transform India’s AgriTech Landscape</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/you-need-this-ai-companion-for-farming-in-india/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>You need this AI companion for farming in India</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/what-does-the-future-of-farming-look-like/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>What does the future of farming look like?</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/how-ai-and-iot-are-reshaping-indian-agriculture/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>How AI and IoT are reshaping Indian Agriculture</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/why-its-still-magic-when-automation-comes-into-play-in-modern-farming/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>Why it’s still magic when automation comes into play in modern farming?</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href="${url}/blog/what-happens-when-you-start-investing-in-smart-farming/">
                        <span style={{ color: 'rgb(17, 85, 204)' }}>What happens when you start investing in smart farming?</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/helping-farmers-with-free-agrotechnology-fasalam-farming-app/`}>
                        <span style={{ color: 'rgb(17, 85, 204)' }}>Helping Farmers with Free Agrotechnology: Fasalam Farming App</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/modernising-mushroom-cultivation-in-india/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Modernising Mushroom Cultivation in India</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/from-data-to-harvest-the-power-of-weather-stations-farm-management-tools/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>From Data to Harvest: The Power of Weather Stations &amp; Farm Management Tools</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/indians-innovative-agritech-startups-an-article-by-rashail-agro-cultivating-innovation-a-look-at-indias-thriving-agritech-ecosystem/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Indian’s Innovative Agritech Startups | An Article by Rashail Agro Cultivating Innovation: A Look at India’s Thriving Agritech Ecosystem</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/precision-farming-101-introduction-benefits-implementation/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Precision Farming 101: Introduction, Benefits &amp; Implementation</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/connecting-soil-technology-and-farmers-our-journey-at-krishi-expo-2024-indore/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Connecting Soil, Technology, and Farmers: Our Journey at Krishi Expo 2024, Indore</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/revolutionizing-irrigation-how-remotely-controlled-motors-are-making-farmers-lives-easier/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Revolutionizing Irrigation: How Remotely Controlled Motors Are Making Farmers’ Lives Easier</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}/blog/crops-clouds-rashail-agros-journey-into-the-iot-farming-frontier/`}>
                        <span style={{ color: 'rgb(74, 110, 224)' }}>Crops &amp; Clouds: Rashail Agro's Journey into the IoT Farming Frontier</span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Navigations;
