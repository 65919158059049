import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';

// General-purpose TabView Component
function TabView({ tabs }) {
    const [activeTab, setActiveTab] = useState(tabs[0]?.key || "");

    return (
        <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            {/* Tab Navigation */}
            <Nav variant="tabs" className="justify-content-center">
                {tabs.map((tab) => (
                    <Nav.Item key={tab.key}>
                        <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>

            {/* Tab Content */}
            <Tab.Content className="mt-4">
                {tabs.map((tab) => (
                    <Tab.Pane eventKey={tab.key} key={tab.key}>
                        {tab.content}
                    </Tab.Pane>
                ))}
            </Tab.Content>
        </Tab.Container>
    );
}

export default TabView;
