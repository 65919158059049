import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./CardGrid.css"; // Import the custom CSS file

const BlogGrid = ({ blogs }) => {
  return (
    <Container>
      <Row>
        {blogs.slice(0, 8).map((blog, index) => (
          <Col
            key={index}
            xs={6}
            sm={6}
            md={3}
            xl={3}
            className="mb-4 blog-item"
          >
            <Card className="custom-card">
              <div className="card-image-container">
                {typeof blog.image === "string" ? (
                  <Card.Img
                    variant="top"
                    src={blog.image}
                    className="card-image"
                  />
                ) : (
                  <div className="card-image">{blog.image}</div>
                )}
              </div>
              <Card.Body className="card-body1">
                <Card.Text>{blog.text}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BlogGrid;
