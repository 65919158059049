import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { forumUrl } from "../Constants/urls";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const Forum = () => {
    const { slug } = useParams();
    const [forum, setForum] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${forumUrl}${slug}`);
                setForum(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [slug]);

    if (!forum) return <div>Loading...</div>;

    const formatText = (text) => {
        if (!text) return '';
        return text.split(/\r\n|\r|\n/).map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };
    return (
        <div className="container mt-5">
            <Helmet>
                <title>{forum.meta_title}</title>
                <meta name="description" content={forum.meta_header} />
            </Helmet>
            <div className="row justify-content-center">
                <div className="col-lg-6 mb-4 d-flex flex-column align-items-center bg-light p-4 rounded">
                    <div className="d-flex align-items justify-content-start mb-3 w-100" style={{ justifyContent: "center" }}>
                        <img src={forum.user.image || '/default-user.png'} alt="User" className="rounded-circle" width="50" height="50" />
                        <div className="ms-3">
                            <h5 className="mb-1">{forum.user.first_name} {forum.user.last_name}</h5>
                            <p className="text-muted mb-0">
                                <FontAwesomeIcon icon={faClock} /> {forum.created_at}
                            </p>
                        </div>
                    </div>
                    <img src={forum.images[0]} alt="Forum" className="img-fluid rounded" style={{ maxHeight: "500px", objectFit: "cover" }} />
                </div>
                <div className="col-lg-6 d-flex flex-column" style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <div className="bg-light p-4 rounded flex-grow-1">
                        <h3 className="mb-3">{forum.question}</h3>
                        <p>{formatText(forum.description)}</p>
                    </div>
                </div>
            </div>
            <div className="emptydiv" style={{ height: "200px" }}></div>
        </div>
    );
};

export default Forum;
