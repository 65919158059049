import React from 'react'
import notfound from '../Images/Navbar/notfount.webp'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <>
            <div className="row mt-5 mb-2 justify-content-center">
                <div class="col-md-10 text-center">
                    <div className='mt-5'>
                        <img src={notfound} alt="Landingimg" className="img-fluid" />
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-10 justify-content-center'>
                    <div
                        className='col-md-2 col-6 readmore_blog text-center custom-span mt-2 mx-auto' // Add mx-auto class for horizontal centering
                        style={{ cursor: "pointer" }}
                    >
                        <Link to='/' style={{ textDecoration: "none", color: "#fff" }}>
                        Back to Homepage
                        </Link>
                       
                    </div>
                </div>
            </div>
            <div className="emptydiv" style={{ height: '200px' }}>
            </div>
        </>

    )
}

export default PageNotFound
