import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleDecrement, handleIncrement, remove } from "../Redux/cartSlice";
import { useNavigate } from "react-router-dom";
import emptycart_gif from "../Images/Navbar/empty_cart.gif";
import Buynowform from "../Components/Buynowform";
import { Card, Modal, Button } from "react-bootstrap";
import Buynowformcart from "../Components/Buynowformcart";
import { Title, Meta, HeadProvider, Link as HeadLink } from "react-head";

const Cart = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [Price, setPrice] = useState(null);
  const [productName, setproductName] = useState(null);
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setShowApplicationForm(false);
  };
  const handleRemove = (product_id) => {
    dispatch(remove(product_id));
  };

  const handleInc = (product) => {
    dispatch(handleIncrement(product));
  };
  // console.log(products);

  const handleDec = (product) => {
    dispatch(handleDecrement(product));
  };

  const totalPrice = () => {
    let total = 0;
    products.forEach((product) => {
      total += Math.round(product.product_qty * product.product_price -
        product.product_price *
        (product.product_discount /
          100));
    });
    return total.toFixed(2);
  };

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    product_name: productName,
    // description: '',
    address: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    phone_number: "",
    email: "",
    // product_name: '',
    // description: '',
    address: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation
    let formIsValid = true;
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === "") {
        formIsValid = false;
        newErrors[key] = `${getFieldName(key)} is required`;
      }
    });

    if (!formIsValid) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // console.log('Form submitted successfully');
        setFormData({
          name: "",
          phone_number: "",
          email: "",
          address: "",
        });
        handleModalClose();
      } else {
        console.error("Error submitting form");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAfterPayment = async (e) => {
    setShowModal(false);
    products.forEach((product) => {
      handleRemove(product.product_id);
    });
  };

  const getFieldName = (fieldName) => {
    return fieldName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // const handleBuyNow = () => {
  //     if (products && products.length > 0) {
  //         const allProductNames = products.map(product => product.product_name).join(', ');
  //         setproductName(allProductNames);
  //         setPrice(totalPrice());
  //         handleModalOpen();
  //     } else {
  //         console.error("No products found");
  //     }
  // };

  const handleBuyNow = () => {
    if (products && products.length > 0) {
      // Construct productName with quantities
      const productNameWithQuantities = products.map((product) => {
        return {
          name: product.product_name,
          quantity: product.product_qty.toString(),
        };
      });
      setproductName(JSON.stringify(productNameWithQuantities));
      setPrice(totalPrice());
      handleModalOpen();
    } else {
      console.error("No products found");
    }
  };

  return (
    <>
      <HeadProvider>
        <Title>Smart Farming Solutions by Rashail Agro</Title>
        <Meta
          name="description"
          content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more."
        />
        <base target="_blank" href="https://rashailagro.in/cart/" />
        <HeadLink rel="canonical" content="https://rashailagro.in/cart/" />
        <Meta
          name="keywords"
          content="smart farming, precision farming, new technologies in agriculture, iot in agriculture, internet of things in agriculture, farming in india, farming app, farming and agriculture, farmer tools, farmer in agriculture, farm equipment, artificial intelligence in agriculture, ai in agriculture, agriculture technology, agriculture startups in india, agriculture in iot, agriculture in india, agriculture farming, agriculture equipment machine, agriculture equipment list, agriculture equipment, agriculture department, agriculture app, agriculture and technology, agricultural services, agricultural products, agricultural machinery, agriculture, agricalchar farm, agri tech, agri startups in india, crop management solution, iot smart agriculture"
        />
        <Meta
          property="og:title"
          content="Smart Farming Solutions by Rashail Agro"
        />
        <Meta
          property="og:description"
          content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more."
        />
        <Meta property="og:url" content="https://rashailagro.in/cart/" />
        <Meta property="og:type" content="website" />
        <Meta
          name="twitter:title"
          content="Smart Farming Solutions by Rashail Agro"
        />
        <Meta
          name="twitter:description"
          content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more."
        />
        <Meta property="twitter:url" content="https://rashailagro.in/cart/" />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div>
        {products.length === 0 ? (
          <div className="container text-center mt-5">
            <h2>No products available in cart</h2>
            <div className="row mt-5 mb-2 justify-content-center">
              <div class="col-md-12 text-center">
                <img
                  src={emptycart_gif}
                  alt="best_farming_app_fasalam"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="container mb-5">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="custom-table-head">
                        <tr>
                          <th scope="col">S.NO</th>
                          <th>IMAGES</th>
                          <th scope="col">PRODUCT</th>
                          <th scope="col">PRICE</th>
                          <th scope="col">QUANTITY</th>
                          <th scope="col">AMOUNT</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="custom-table-body">
                        {products &&
                          products.map((product, index) => {
                            const serialNumber = index + 1;
                            return (
                              <tr
                                key={index}
                                className="mt-3"
                                style={{ height: "120px" }}
                              >
                                <td scope="row">{serialNumber}</td>
                                <td>
                                  <img
                                    src={product.product_images[0]}
                                    className="card-img-top imgproduct rounded img-fluid"
                                    alt="..."
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                      width: "100%",
                                      height: "auto",
                                      objectFit: "contain",
                                    }}
                                  />
                                </td>
                                <td>
                                  {product.product_name.slice(0, 30)}
                                  <br />
                                  {product.product_name.slice(30)}
                                </td>
                                <td>₹ {Math.round(product.product_price -
                                  product.product_price *
                                  (product.product_discount /
                                    100))}</td>
                                <td>
                                  <div className="button-container">
                                    <button
                                      className="round-button"
                                      type="button"
                                      onClick={() => {
                                        handleDec(product);
                                      }}
                                    >
                                      -
                                    </button>
                                    <div className="quantity-display">
                                      {product.product_qty}
                                    </div>
                                    <button
                                      className="round-button"
                                      type="button"
                                      onClick={() => {
                                        handleInc(product);
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  ₹{Math.round(product.product_price -
                                    product.product_price *
                                    (product.product_discount /
                                      100)) * product.product_qty}
                                </td>
                                <td>
                                  <button
                                    className="btn button-cart"
                                    onClick={() =>
                                      handleRemove(product.product_id)
                                    }
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mb-5">
              <div className="row justify-content-center mb-5">
                <div class="col-md-12 text-center">
                  <p className="text-start">AMOUNT PAYABLE</p>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>MRP Total</p>
                    <p>₹{totalPrice()}</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Additional Discount</p>
                    <p>₹0</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px dashed  #000",
                    }}
                  >
                    <p>Shipping Charges</p>
                    <p>₹0</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Total Payable</p>
                    <p>₹{totalPrice()}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      className="blue-button-cart"
                      onClick={() => handleBuyNow()}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Modal
          size="lg"
          show={showModal}
          onHide={handleModalClose}
          //  scrollable
          dialogClassName="custom-modal"
        >
          <Modal.Header
            className="mt-2"
            closeButton
            style={{ borderBottom: "none" }}
          >
            <Modal.Title className="text-center">Checkout</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="col-md-12 col-sm-12 text-center mx-auto">
              <div className="mt-3">
                <Buynowformcart
                  productName={productName}
                  Price={Price}
                  onSubmit={handleAfterPayment}
                  setShowModal={setShowModal}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="emptydiv" style={{ height: "200px" }}></div>
      </div>
    </>
  );
};

export default Cart;
