import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  faArrowRight,
  faShare,
  faShareAlt,
  faHeart,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YouTubePlayer from "../Components/YouTubePlayer";
import YouTubePlayersblog from "../Components/YouTubePlayersblog";
import fasalamlogo from "../Images/blog/fasalamlogo.webp";
import { Helmet } from "react-helmet";
import {
  Link,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { blogdata, instablogdata } from "../Constants/Blogdata";
import { Videodata } from "../Constants/Homedata";
import { Title, Meta, HeadProvider, Link as HeadLink } from "react-head";
import blogdetailsTopimg from "../Images/blog/732a6ef0a9a7fc1559661d2ab5fd9f13.jpg";
import blogdetailsmobileimg from "../Images/blog/3745f24b61aca5aab88a72b8bd1b826c.png";
import { Modal } from "react-bootstrap";
import { bloglist } from "../Redux/GetblogSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import axios from "axios";
import { allblog, commenturl, likeurl, serverToken } from "../Constants/urls";
import ScrollToTop from "../Components/scroll";

const Blogdetails = () => {
  const sliderRef = useRef(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const bloglists = useSelector((state) => state.blog.data.results);
  const url = window.location.href;
  const url1 = window.location.origin;
  const parturl = url.replace(url1, "").replace("/blog/", "");
  const slug = parturl.endsWith("/") ? parturl.replace("/", "") : parturl;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleModal1 = () => {
    setShowModal1(!showModal1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        await dispatch(bloglist());
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const [like, setlike] = useState();
  const [comment, setComment] = useState();
  const [blog, setblog] = useState();
  const [blogid, setblogid] = useState();
  const blog_id = blog && blog.id;
  const fetchData = async (page = 1) => {
    try {
      //   setLoading(true);
      const token = serverToken;
      const response_blog = await axios.get(`${allblog}?slug=${slug}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      const blogId = response_blog.data.results[0]?.id;
      const response = await axios.get(`${likeurl}${blogId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const response_comments = await axios.get(`${commenturl}${blogId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      setblog(response_blog.data.results[0]);

      setlike(response.data);
      setComment(response_comments.data);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sanitizedContent =
    blog && blog.content.replace(/<p data-f-id="pbf".*?>.*?<\/p>/g, "");

  const handleHoverExplore = (index) => {
    setHoveredCard(index);
  };

  const goToPreviousSlide1 = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide1 = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const navigate = useNavigate();
  // const toComponentB = (data) => {
  //     navigate(`/blog/${encodeURIComponent(data.slug)}`, { state: { data } });
  //     window.location.reload();
  // };
  const toComponentB = (data) => {
    const newUrl = `/blog/${encodeURIComponent(data.slug)}`;
    navigate(newUrl, { state: { data } });
    window.location.replace(newUrl); // Replace the current URL with the new URL
  };

  function truncateHtml(html, maxLength) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const textContent = doc.body.textContent || "";
    return textContent.length > maxLength
      ? `${textContent.substring(0, 40)}...`
      : textContent;
  }

  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Rashail Agro Blog: Latest Insights in Smart Farming</title>
                <meta name="description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <base target="_blank" href="https://rashailagro.in/blog/" />
                <link rel="canonical" href="https://rashailagro.in/blog/" />
                <meta name="keywords" content="smart farming tips, farming solutions, farming equipments, farming machinery, farming tools, agriculture sensors, iot in agriculture, smart farming solution, smart iot farming, blogs, agri revolution, farming revolution, youtube channel, instagram page, rashail agro, agritech startup, mp startup, success stories, farmers story" />
                <meta property="og:title" content="Rashail Agro Blog: Latest Insights in Smart Farming" />
                <meta property="og:description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <meta property="og:url" content="https://rashailagro.in/blog/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro Blog: Latest Insights in Smart Farming" />
                <meta name="twitter:description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <meta property="twitter:url" content="https://rashailagro.in/blog/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      <HeadProvider>
        <Title>{blog && blog?.meta_title}</Title>
        <Meta name="description" content={blog && blog?.meta_header} />
        <base target="_blank" href={`https://rashailagro.in/blog/${slug}/`} />
        <HeadLink
          rel="canonical"
          content={`https://rashailagro.in/blog/${slug}/`}
        />
        <Meta
          name="keywords"
          content="smart farming tips, farming solutions, farming equipments, farming machinery, farming tools, agriculture sensors, iot in agriculture, smart farming solution, smart iot farming, blogs, agri revolution, farming revolution, youtube channel, instagram page, rashail agro, agritech startup, mp startup, success stories, farmers story"
        />
        <Meta property="og:title" content={blog && blog?.meta_title} />
        <Meta property="og:description" content={blog && blog?.meta_header} />
        <Meta
          property="og:url"
          content={`https://rashailagro.in/blog/${slug}/`}
        />
        <Meta property="og:type" content="website" />
        <Meta name="twitter:title" content={blog && blog?.meta_title} />
        <Meta name="twitter:description" content={blog && blog?.meta_header} />
        <Meta
          property="twitter:url"
          content={`https://rashailagro.in/blog/${slug}/`}
        />
        <Meta property="twitter:type" content="website" />
        <script type="application/ld+json">blog?.schema</script>
      </HeadProvider>
      <div className="row Termconditionbackground d-flex align-items-center">
        <div
          animationIn="fadeInDownBig"
          className="col-md-11 col-sm-12 text-start mx-auto"
        >
          <h1 className="mt-5 privacy_heading">
            {/* Unveiling the farming process  */}
            {blog && blog.title}
          </h1>
          <p>
            <span className="blogdetaildatehead">
              Date Published: Feb 02, 2024
            </span>
            <span class="vertical-line"></span>{" "}
            <span className="blogdetaildatehead">Author: Rashail Agro</span>
          </p>
          <p className="text-start border_new">
            {/* Cultivating Abundance: Insights and Tips for Thriving Farms on Our Blog */}
            {blog && blog.into}
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <Link
                to="/blog/all-blogs"
                style={{ textDecoration: "none" }}
                className="underlineGreen"
              >
                <span className="blogdetailall">All Blogs</span>
              </Link>
              <span class="icon_allblog">&gt;</span>
              <span class="blogdetailall">Farming Process</span>
            </p>
            <p className="mt-1 d-flex">
              {/* {like?.count > 0 ? (
                                <span className='mx-2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faHeart} size="lg" style={{ color: '#9FAFA4' }} />
                                    <span className='like_text'>{like?.count} Likes</span>
                                </span>
                            ) : (
                                <span className='mx-2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faHeart} size="lg" style={{ color: '#9FAFA4' }} />
                                    <span className='like_text'>Likes</span>
                                </span>
                            )}
                            {comment?.count > 0 ? (
                                <span className='mx-2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: "pointer" }} onClick={toggleModal1}>
                                    <FontAwesomeIcon icon={faComment} size="lg" style={{ color: '#9FAFA4' }} />
                                    <span className='like_text'>{comment?.count} Likes</span>
                                </span>
                            ) : (
                                <span className='mx-2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: "pointer" }}
                                    onClick={toggleModal1}>
                                    <FontAwesomeIcon icon={faComment} size="lg" style={{ color: '#9FAFA4' }} />
                                    <span className='like_text'>Comments</span>
                                </span>
                            )} */}
              <span
                className="mx-2"
                onClick={toggleModal}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faShareAlt}
                  size="lg"
                  style={{ color: "#9FAFA4" }}
                />
                <span className="like_text">Share</span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-2 justify-content-center">
        <div class="col-md-8 text-center">
          <div className="">
            <img
              src={blog && blog.image}
              alt="fasalam-app-features"
              className="img-fluid blogdetailsTopimg"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </div>
          {/* <span
                        dangerouslySetInnerHTML={{
                            __html: blogdetails_data.content,
                        }}
                    /> */}
        </div>
      </div>

      <div className="row Termconditionbackground d-flex align-items-center">
        <div
          animationIn="fadeInDownBig"
          className="col-md-11 col-sm-12 text-start mx-auto"
        >
          {/* Your existing JSX code */}
          <div className="share-button">
            {/* <button onClick={toggleModal}>
                            <FontAwesomeIcon icon={faShare} size="lg" />
                        </button> */}
            {/* Modal */}
            <Modal show={showModal} onHide={toggleModal} centered>
              <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>Share</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="share-icons">
                  <WhatsappShareButton url={window.location.href}>
                    <WhatsappIcon
                      size={32}
                      round
                      style={{ marginRight: "10px" }}
                    />
                  </WhatsappShareButton>
                  <FacebookShareButton url={window.location.href}>
                    <FacebookIcon
                      size={32}
                      round
                      style={{ marginRight: "10px" }}
                    />
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <TwitterIcon
                      size={32}
                      round
                      style={{ marginRight: "10px" }}
                    />
                  </TwitterShareButton>
                  <EmailShareButton url={window.location.href}>
                    <EmailIcon
                      size={32}
                      round
                      style={{ marginRight: "10px" }}
                    />
                  </EmailShareButton>
                </div>
                <div>
                  <div class="mb-3 mt-4" style={{ position: "relative" }}>
                    {/* <label for="exampleFormControlInput1" class="form-label">Email address</label> */}
                    <input
                      type="text"
                      class="form-control shareblogdetails"
                      value={window.location.href}
                      id="exampleFormControlInput1"
                    />
                    <button
                      style={{
                        position: "absolute",
                        right: "5px",
                        top: "30%",
                        transform: "translateY(-50%)",
                        color: "#FFFFFF",
                        backgroundColor: "#2FB95D",
                      }}
                      className="btn  mt-2 btn-sm"
                      onClick={() => {
                        const inputField = document.getElementById(
                          "exampleFormControlInput1"
                        );
                        inputField.select(); // Select the text inside the input field
                        document.execCommand("copy"); // Execute the copy command
                      }}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={showModal1} onHide={toggleModal1} centered>
              <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>Comments</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {comment && comment.results && comment.results.length > 0 ? (
                  <div className="comments">
                    {comment.results.map((data, index) => (
                      <p key={index}>
                        <span className="checkbox_iotdeatails"></span>
                        {data.comment}
                      </p>
                    ))}
                  </div>
                ) : (
                  <p className="comments text-center">
                    <strong>No Comments Available</strong>
                  </p>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-8 col-sm-8 text-center mx-auto">
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizedContent,
            }}
          />
        </div>
      </div>
      <div className="row mt-5 mb-5 client-feedback-mobile ">
        <div className="col-md-11 col-12 justify-content-center">
          <div className="d-flex  flex-column flex-md-row justify-content-between mx-2 mb-3">
            <div>
              <h2 className="mx-md-5 text-center">
                <span className="privacy_heading"> Blogs that </span>
                <span className="policy_heading">might Interest you</span>
              </h2>
            </div>
            <div className="d-flex text-center justify-content-center">
              <button
                className="circular-button mx-2"
                onClick={goToPreviousSlide1}
              >
                <i className="fa fa-chevron-left" />
              </button>
              <button className="circular-button mx-2" onClick={goToNextSlide1}>
                <i className="fa fa-chevron-right" />
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-11 text-center mx-auto mt-3">
          <div className="testimonial-slider-container ">
            <Slider ref={sliderRef} {...sliderSettings}>
              {bloglists &&
                bloglists.slice(0, 4).map((data, index) => (
                  <div key={index} className="testimonial-slide">
                    <a
                      href={data.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="card custom-card-style_blog"
                        style={{ width: "20rem" }}
                      >
                        <div className="card_blog_first">
                          <img
                            src={data.image}
                            alt={`Blog Image ${index}`}
                            className="img-fluid all-blog_img"
                          />
                          <div className="card-body">
                            <h2 className="card-title card_home_heading text-start">
                              {data.title.length > 40
                                ? `${data.title.substring(0, 40)}...`
                                : data.title}
                              {data.title.length > 40}
                            </h2>
                            {data.content && (
                              <p
                                className="card-text faq_answer text-start"
                                dangerouslySetInnerHTML={{
                                  __html: truncateHtml(data.content, 40),
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <span
                          className={`readmore_blog text-center mt-2 ${
                            hoveredCard === index ? "show-arrow" : ""
                          }`}
                          onMouseEnter={() => handleHoverExplore(index)}
                          onMouseLeave={() => handleHoverExplore(null)}
                          onClick={() => {
                            toComponentB(data);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {hoveredCard === index ? (
                            <>
                              Read more <FontAwesomeIcon icon={faArrowRight} />
                            </>
                          ) : (
                            "Read more"
                          )}
                        </span>
                      </div>
                    </a>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="emptydiv" style={{ height: "200px" }}></div>
    </div>
  );
};

export default Blogdetails;
