import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { allblog, serverToken } from "../Constants/urls";



const initialState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
};

export const bloglist = createAsyncThunk(
    'blog/getblog',
    async (page, { rejectWithValue }) => {
        try {
            const token = serverToken;
            const response = await axios.get(
                allblog,

                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const GetblogSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(bloglist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(bloglist.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            })
            .addCase(bloglist.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload;
            });
    },
});

export default GetblogSlice.reducer;
