
import blogharvest from '../Images/Home/blog_harvest.webp'
import cropsblog from '../Images/Home/cropsblog.webp'
import news1 from '../Images/Home/news1.webp'
import revolutionblog from '../Images/Home/revolutionblog.webp'
import instablog1 from '../Images/blog/instablog1.webp'
import instablog2 from '../Images/blog/instablog2.webp'
import instablog3 from '../Images/blog/instablog3.webp'
import instablog4 from '../Images/blog/instablog4.webp'
import instablog5 from '../Images/blog/instablog5.webp'
import cultivatinginnovations from '../Images/Home/cultivatinginnovations.webp'
import precisionfarming101 from '../Images/Home/precisionfarming101.webp'
import mushroom_cultivation from '../Images/blog/mushroom-cultivation-65dec455a1eb7.webp'


const blogdata = [
    {
        img: mushroom_cultivation,
        heading: "Modernising Mushroom Cultivation in India",
        description: "An AI-IoT Powered Tech Revolution In recent years, the ...",
        link: "https://medium.com/@rashailagro/modernising-mushroom-cultivation-in-india-05f7c1a4e45e",
        platform: "Medium"
    },
    {
        img: blogharvest,
        heading: "From Data to Harvest: The Power of Weather ...",
        description: "Welcome to Rashail Agro’s innovative world of farming solutions! In this article...",
        link: "https://medium.com/@rashailagro/from-data-to-harvest-the-power-of-weather-stations-farm-management-tools-4edb261d7576"
    },
    {
        img: cropsblog,
        heading: "Crops & Clouds: Rashail Agro's Journey...",
        description: "Dive into our latest blog to discover how our innovative IoT products and...",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7164212363364032512"
    },
    {
        img: cultivatinginnovations,
        heading: "Cultivating Innovation: A Look at...",
        description: "Today, we delve into the exciting world of Indian Agritech, a vibrant ecosystem...",
        link: "https://medium.com/@rashailagro/cultivating-innovation-a-look-at-indias-thriving-agritech-ecosystem-30cc73c8b7bd"
    },
    {
        img: precisionfarming101,
        heading: "Precision Farming 101: Introduction, Benefits...",
        description: "In the ever-evolving realm of agriculture, Precision Farming stands tall as a beacon of...",
        link: "https://medium.com/@rashailagro/precision-farming-101-introduction-benefits-implementation-674c716926c3"
    },
    
    {
        img: revolutionblog,
        heading: "Revolutionizing Irrigation: How Remotely Controlled",
        description: "Imagine this: you’re relaxing at home, enjoying a cup of chai...",
        link: "https://medium.com/@rashailagro/revolutionizing-irrigation-how-remotely-controlled-motors-are-making-farmers-lives-easier-a3d2dd50f74b"
    },
    {
        img: news1,
        heading: "Connecting Soil, Technology, and Farmers",
        description: "Rashail Agro, a name synonymous with agriculturalinnovation, had the..",
        link: "https://www.linkedin.com/pulse/connecting-soil-technology-farmers-our-journey-krishi-expo-tpv6f"
    },
];

const instablogdata = [
    {
        img: instablog1,
        name: "Rashail Agro",
        city: "Indore, MP",
        date: "26 Jan 2024",
        farm: ".Team",
        heading: "Participation Certificate at Krishi Expo 2024",
        description: "Bharat Agritech thanks Team Rashail Agro for participating in Krishi Expo 2024",
        link: "https://www.instagram.com/p/C26O0RwopUV/?igsh=ZWI2YzEzYmMxYg%3D%3D"
    },
    {
        img: instablog2,
        name: "Rashail Agro",
        city: "Indore, MP",
        date: "26 Jan 2024",
        farm: ".Product Demo",
        heading: "Features of Rashail Agro's GSM Motor Controller",
        description: "किसान भाइयों, सिंचाई की परेशानी अब दूर! #RashailAgro लाया है क्रांतिकारी #MobileStarter!",
        link: "https://www.instagram.com/reel/C23xdohpzvp/?igsh=ZWI2YzEzYmMxYg%3D%3D"
    },
    {
        img: instablog3,
        name: "Rashail Agro",
        city: "Indore, MP",
        date: "27 Jan 2024",
        farm: ".Farmer's Review",
        heading: "#IotDataLogger के लिए किसान भाई विन्डो पाटीदार का अनुभव",
        description: "#AgriExpo2024 में विनोद पाटिदार जी ने #RashailAgro के Smart IoT Data Logger को देखा और उसके प्रभाव से हुए रोमांचित!",
        link: "https://www.instagram.com/reel/C21JbA7oVka/?igsh=ZWI2YzEzYmMxYg%3D%3D"
    },
    {
        img: instablog4,
        name: "Rashail Agro",
        city: "Indore, MP",
        date: "28 Jan 2024",
        farm: ".Product Review",
        heading: "Rashail Agro के लिए Youtube Channel के संस्थापक की समीक्षा",
        description: "किसानों के हितैषी यूट्यूब चैनल FARMER PROTECH के संस्थापक #AgriExpo2024 में #RashailAgro के स्टॉल पर आए और लौट गए उम्मीदों का नया बीज लेकर!",
        link: "https://www.instagram.com/reel/C2wZ7vmtwCE/?igsh=ZWI2YzEzYmMxYg%3D%3D"
    },
    {
        img: instablog5,
        name: "Rashail Agro",
        city: "Indore, MP",
        date: "28 Jan 2024",
        farm: ".Team",
        heading: "Happy Farmers with Rashail Agro Team",
        description: "1200+ farmers visited us at Krishi Expo 2024. Read full story.",
        link: "https://www.instagram.com/p/C2m94fLtjcZ/?igsh=ZWI2YzEzYmMxYg%3D%3D&img_index=1"
    },

];

export { blogdata,instablogdata };
