import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Prices, Shipping } from "../Constants/Homedata";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import { serverToken, verify_url, weborder_url } from "../Constants/urls";
import { clearCart } from "../Redux/cartSlice";
import { useDispatch } from "react-redux";
import { Card, Modal, Button } from "react-bootstrap";

const Buynowformcart = ({ onSubmit, productName, Price }) => {

  const productNames = JSON.parse(productName);
  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [Razorpay, isLoaded] = useRazorpay();
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone_no: "",
    order_quantity: productNames.length,
    payment_mode: "Razorpay",
    product: productName,
    payment_status: "Pending",
    address: "",
    product_price: Math.round(Price),
    shipping_price: Shipping,
    user_paid_amount: Math.round(Price + Shipping),
  });

  const notify = (data) => toast(data);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Reset error message when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formIsValid = true;
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const value = formData[key];

      if (typeof value === "string" && value.trim() === "") {
        formIsValid = false;
        newErrors[key] = `${getFieldName(key)} is required`;
      }
    });

    if (!formIsValid) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    try {
      const token = serverToken;
      const response = await fetch(weborder_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { order_id, razorpay_key_id, amount } = responseData;
        setFormData((prevData) => ({
          ...prevData,
          order_id: order_id,
        }));

        if (isLoaded) {
          handlePayment(order_id, razorpay_key_id, amount);
        } else {
          notify("Razorpay SDK not loaded.");
        }
      } else {
        notify("Error submitting form");
        console.error("Error submitting form");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getFieldName = (fieldName) => {
    return fieldName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handlePayment = useCallback(
    async (orderId, key, amount) => {
      try {
        const options = {
          key: key,
          amount: amount * 100, // Amount in paise
          currency: "INR",
          name: "Rashail Agro",
          description: `${productName}-Purchase order`,
          image: "https://rashailagro.in/static/media/Rashail%20Agro%20LOGO%20(1).2d847f5d699fbc1530f8.png",
          order_id: orderId,
          handler: async (response) => {
            try {
              const token = serverToken;
              const apiResponse = await fetch(verify_url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Token ${token}`,
                },
                body: JSON.stringify({
                  razorpay_order_id: orderId,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                }),
              });
              if (apiResponse.ok) {
                const responseData = await apiResponse.json();
                setFormData((prevData) => ({
                  ...prevData,
                  payment_status: "Success",
                }));
                notify("Payment verified successfully!");
                onSubmit();
              } else {
                const result = await apiResponse.json();
                notify(`Payment verification failed: ${result.error}`);
              }
            } catch (error) {
              console.error("Error submitting payment details:", error);
              notify("Error submitting payment details");
            }
          },
          prefill: {
            name: formData.user_name,
            contact: formData.user_phone_no,
          },
          notes: {
            address: formData.address,
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpay = new window.Razorpay(options);
        rzpay.open();
      } catch (error) {
        console.error("Error in handlePayment:", error);
        notify("Error in handlePayment");
      }
    },
    [formData, isLoaded]
  );

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 ">
            <h4 className="text-start billing_details">Billing Details</h4>
            <div class="mb-2 text-start">
              <label for="exampleInputtext" class="form-label ">
                Full Name
              </label>
              <input
                type="text"
                class="form-control"
                name="user_name"
                value={formData.user_name}
                onChange={handleChange}
              />
              {errors.user_name && (
                <div className="error-message text-end">{errors.user_name}</div>
              )}
            </div>
            <div class="mb-2 text-start">
              <label for="exampleInputphone" class="form-label ">
                Phone Number / Whatsapp
              </label>
              <input
                type="number"
                class="form-control"
                name="user_phone_no"
                value={formData.user_phone_no}
                onChange={handleChange}
              />
              {errors.user_phone_no && (
                <div className="error-message text-end">
                  {errors.user_phone_no}
                </div>
              )}
            </div>
            <div class="mb-2 text-start">
              <label for="floatingTextarea">Address</label>
              <textarea
                class="form-control"
                id="floatingTextarea"
                name="address"
                value={formData.address}
                onChange={handleChange}
              ></textarea>
              {errors.address && (
                <div className="error-message text-end">{errors.address}</div>
              )}
            </div>
          </div>
          <div className="col-md-6  align-self-end">
            <h4 className="text-start billing_details">Order Details</h4>
            <div className="row product-info ">
              <div className="d-flex mx-auto">
                <div className="col-md-6 ">
                  <div className="product-name-container">
                    <p className="text-start buynow_productheading">
                      {productNames.map((product, index) => (
                        <div key={index}>
                          <div className="text-start flex-grow-1 align-items-stretch">
                            {product.name} X {product.quantity} ,
                          </div>
                        </div>
                      ))}
                    </p>
                  </div>
                  <p className="text-start buynow_productheading">Shipping</p>
                  <p className="text-start buynow_productheading">
                    <strong>Total</strong>
                  </p>
                </div>
                <div className="col-md-6 ">
                  <p className="text-start mx-5 buynow_productheading">
                    ₹ {Price}
                  </p>
                  <p className="text-start mx-5 buynow_productheading">
                    <strong>Free</strong>
                  </p>
                  <p className="text-start mx-5 buynow_productheading">
                    <strong>₹ {parseFloat(Price) + parseFloat(Shipping)}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-5 mt-5 text-end ">
              <button
                type="submit"
                className="btn btn-secondary "
                style={{
                  backgroundColor: "#2FB95D",
                  border: "none",
                  width: "100%",
                }}
              // onClick={handlePayment}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Buynowformcart;
