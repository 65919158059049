// data.js
import gsmmotor from '../Images/Home/gsm motor.png'
import farmwhetherstation from '../Images/Home/farmwhetherstation.webp'
import iotmotorstarter from '../Images/Home/iot-mobile-starter.webp'
import solarinsect from '../Images/Home/solarinsect.webp'
import datalogger from '../Images/Home/datalogger.webp'
import review1 from '../Images/Home/review1.png';
import review2 from '../Images/Home/review2.png';
import review3 from '../Images/Home/review3.png';
import review4 from '../Images/Home/review4.png';
import review5 from '../Images/Home/review5.png';
import review6 from '../Images/Home/review6.png';
import review7 from '../Images/Home/review7.png';
import review8 from '../Images/Home/review8.webp';
import review9 from '../Images/Home/review9.webp';
import testimonial2 from '../Images/Home/testimonial2.webp'
import solarfench from '../Images/Home/solarfench.webp'
import phasepreventer from '../Images/Home/auto switch.webp'
import phasedol from '../Images/Home/auto switch m.webp'
import mobilestarter from '../Images/Home/mobilstarter.webp'


// home faq
const faqData = [
  {
    question: 'Q1. What is Rashail Agro and what product do you offer?',
    answer: 'Rashail Agro is an agricultural technology company providing advanced solutions to optimize farm operations and maximize yields. We offer a range of products including GSM Motor Controllers, IoT Irrigation Station, IoT data logger, Farm Stations, Weather Stations, and the Fasalam Mobile App.',
  },
  {
    question: "Q2. What are the benefits of using Rashail Agro's services for my farm?",
    answer: "Our products offer numerous benefits, including Increased yield, High productivity, Reduced costs, Improved decision-making, Time-savings, and Sustainable agricultural practices.",
  },
  {
    question: 'Q3. What are the warranty and support options available for your products?',
    answer: 'We offer comprehensive warranties on all our products and provide ongoing technical support to ensure you get the most out of your investment. Please refer to our product specifications or contact us for specific details.',
  },
  {
    question: 'Q4. Do Farm Stations require a lot of maintenance?',
    answer: 'Our Farm Stations are designed for low maintenance and durability. Regular cleaning and occasional sensor calibration may be necessary, but we offer maintenance packages for added peace of mind.',
  },
  {
    question: 'Q5. Is Fasalam compatible with all Rashail Agro products?',
    answer: 'Yes! The Fasalam mobile app is compatible with all current and future products of Rashail Agro. Experience the magic of IoT technology at your fingertips with Fasalam.',
  },
  {
    question: "Q6. Where can I purchase Rashail Agro's products?",
    answer: 'You can purchase our products through our website www.rashailautomation.com or by contacting our sales team directly at +91 8349503619 ',
  },
];

// home product Data
const IOTproduct = [
  {
    img: gsmmotor,
    name: "GSM Motor Controller 2G/4G",
    url: "/shop/gsm-motor-controller/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: farmwhetherstation,
    name: "IoT Farm Weather Station",
    url: "/shop/iot-farm-weather-station/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: datalogger,
    name: "Farm IoT Data Logger",
    url: "/shop/farm-iot-data-logger/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: datalogger,
    name: "Rapid Soil Tester",
    url: "/shop/rapid-soil-tester/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: iotmotorstarter,
    name: "IoT Motor Starter",
    url: "/shop/iot-motor-starter/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: solarfench,
    name: "Solar Fence Energizer",
    url: "/shop/solar-fence-energizer/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: phasepreventer,
    name: "Phase Preventer Dol",
    url: "/shop/phase-preventer-dol/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: mobilestarter,
    name: "Phase Preventer Mini",
    url: "/shop/phase-preventer-mini/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: solarinsect,
    name: "Solar Insect Trapper",
    url: "/shop/solar-insect-trapper/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  {
    img: phasedol,
    name: "3 Phase DOL Starter",
    url: "/shop/dol-motor-starter/",
    stock:"In stock",
    Description:"Rashail Automations | GSM Motor Controller V3 is a smart and reliable controlling device that helps you control your Irrigation Motor/Submersible...",
    pricewithdescount:4999,
    actualPrice:3999,
    percentoff:20,
  },
  
];

// Review section Data 
const initialImages = [
  {
    img: review1,
    name: "Vishwas Deshmukh",
    desc: "“Gsm motor starter controller best product of this company”",
    rating: 5,
  },
  {
    img: review2,
    name: "Roshan Patil",
    desc: "“Very nice products by rashail tech labs”",
    rating: 4.5,
  },
  {
    img: review3,
    name: "Pandurang Mahalle",
    desc: "“They are really having good products.One of their product GSM motor controller is of great use for farmers like us.”",
    rating: 5,
  },
  {
    img: review4,
    name: "Arav Raut",
    desc: "“Gained conceptual knowledge. Very interactive environment. And friendly mentors . I'm grateful for the guidance.”",
    rating: 5,

  },
  {
    img: review5,
    name: "Slok Kirtane",
    desc: "“The people here are full of enthusiasm and innovative ideas.... Who strive to build great products at cheap price and are customer centric...”",
    rating: 5,
  },
  {
    img: review6,
    name: "Supesh Parkhade",
    desc: "“Amazing company  product. Gsm device is working amazing.this product so nice”",
    rating: 4,

  },
  {
    img: review7,
    name: "Piyush Atkar",
    desc: "“So many automatically and so useful products made by rashail tech labs”",
    rating: 5,

  },
  // {
  //   img: review8,
  //   name: "Swati Jadhao",
  //   desc: "“Gsm motor starter controller best product of this company”",
  //   rating: 4,
  // },
  // {
  //   img: review9,
  //   name: "Slok Kirtane",
  //   desc: "“Very nice products by rashail tech labs”",
  //   rating: 4,
  // },
  // {
  //   img: testimonial2,
  //   name: "Aradhna Ghorpade",
  //   desc: "“Managing my farm has become a breeze! The intuitive interface, coupled with insightful analytics, has significantly boosted my productivity. From crop diagnosis to market insights, it's an all-in-one solution that every modern farmer needs. Highly recommend!”",
  //   rating: 5,
  // }
];

// Video section Data
const Videodata = [
  {
    videoId: '3ZP2vEu9rSI',
  },
  {
    videoId: 'A3PEDQC_UfE',
  },
  {
    videoId: '1YyndZGQxB8',
  },
  {
    videoId: '4PF1sEe1G_w',
  },
  {
    videoId: '1YyndZGQxB8',
  },
  {
    videoId: 'A3PEDQC_UfE',
  },
];

const Prices = 
  {
    "/shop/gsm-motor-controller/":6000,
    "/shop/phase-preventer-mini/":599,
    "/shop/solar-fence-energizer/":3519,
    "/shop/iot-motor-starter/":6000,
    "/shop/phase-preventer-dol/":399,
    "/shop/dol-motor-starter/":3000,
  }
;

const Shipping = 0;
  


export { faqData, IOTproduct,initialImages,Videodata,Prices,Shipping };
