// const { createSlice } = require("@reduxjs/toolkit");

import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: [],
    reducers: {
        // add(state, action) {
        //   state.push(action.payload);
        // },
        add(state, action) {
            const existingIndex = state.findIndex(
                (item) => item.product_id === action.payload.product_id
            );

            if (existingIndex >= 0) {
                state[existingIndex] = {
                    ...state[existingIndex],
                    product_qty: state[existingIndex].product_qty + 1,
                };
                // alert("Increased product quantity", {
                //   position: "bottom-left",
                // });
            } else {
                let tempProductItem = { ...action.payload, product_qty: 1 };
                state.push(tempProductItem);
                // alert("Product added to cart", {
                //   position: "bottom-left",
                // });
            }


        },

        remove(state, action) {
            return state.filter((item) => item.product_id !== action.payload);
        },


        handleDecrement(state, action) {
            const itemIndex = state.findIndex(
                (item) => item.product_id === action.payload.product_id
            );
            if (state[itemIndex].product_qty > 1) {
                state[itemIndex].product_qty -= 1;

            } else if (state[itemIndex].product_qty === 1) {
                const nextCartItems = state.filter(
                    (item) => item.product_id !== action.payload.product_id
                );

                state = nextCartItems;

                return state.filter((item) => item.product_id !== action.payload);
            }
        },

        // handleDecrement(state, action) {
        //   console.log("action");
        //   console.log(action);
        //   let newItem = action.payload;
        //   let existingItem = state.find((item) => item.id === newItem.id);
        //   existingItem.product_qty = parseInt(existingItem.product_qty) - 1;
        //   // existingItem.price =
        //   //   parseInt(existingItem.price) - parseInt(newItem.price);
        // },

        handleIncrement(state, action) {
            // console.log("action");
            // console.log(action);
            let newItem = action.payload;
            let existingItem = state.find((item) => item.product_id === newItem.product_id);
            existingItem.product_qty = parseInt(existingItem.product_qty) + 1;
            // existingItem.price =
            //   parseInt(existingItem.price) + parseInt(newItem.price);
        },
        clearCart(state) {
            state.items = []; // Reset cart to empty array
          },
    },
});


export const selectTotalCount = (state) =>
    state.cart.reduce((total, item) => total + item.product_qty, 0);

export const { add, remove, handleDecrement, handleIncrement,clearCart } =
    cartSlice.actions;
export default cartSlice.reducer;
