import React, { Component } from 'react';

class MapComponent extends Component {
  render() {
    return (
      <div className='mapcomp' style={{ width: '100%', height: '56vh', borderRadius: '30px' , overflow: 'hidden'}}>
        <iframe
          title="Google Maps"
          width="100%"
          height="100%"
          frameBorder="0"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15249885.318783779!2d82.7525294!3d21.0680074!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4890048547c791ef%3A0xe28168dda2c15bee!2sRashail%20Agro!5e0!3m2!1sen!2sin!4v1708761079971!5m2!1sen!2sin"
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export default MapComponent;
