import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useState } from "react";
import { useEffect } from "react";
// import { Title, Meta, HeadProvider, Link as HeadLink } from "react-head";
// import axios from "axios";
// import { add, remove } from "../Redux/cartSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { Card, Modal, Button } from "react-bootstrap";
// import Buynowform from "../Components/Buynowform";
import loading_image from "../Images/blog/loader_img.gif";
// import { enquiryurl, producturl, serverToken } from "../Constants/urls";
// import Demoform from "../Components/Demoform";
// import { BsFillPatchCheckFill } from "react-icons/bs";
// import MyCarousel from "../Components/CaerosaulSlider";
// import "./custom_carousel.css";
// import topImage from "../Images/Shop/top.png";

const Products = () => {
  // const [product, setproduct] = useState();
  // const [loading, setLoading] = useState(true);
  // const products = useSelector((state) => state.cart);
  // const dispatch = useDispatch();
  // const [showModal, setShowModal] = useState(false);
  // const [showModal1, setShowModal1] = useState(false);
  // const [showPaymentModal, setShowPaymentModal] = useState(false);

  // const [Price, setPrice] = useState(null);
  // const [productName, setproductName] = useState(null);
  // const [showApplicationForm, setShowApplicationForm] = useState(false);
  // const handleModalOpen = () => {
  //   setShowModal(true);
  // };
  // const handleModalClose = () => {
  //   setShowModal(false);
  //   setShowApplicationForm(false);
  // };
  // const handleModalOpen1 = (product) => {
  //   setproductName(product.product_name);
  //   setShowModal1(true);
  // };
  // const handleModalClose1 = () => {
  //   setShowModal1(false);
  // };

  // // Function to handle "Buy Now" button click
  // const handleBuyNow = (product) => {
  //   // setSelectedProduct(product);
  //   setproductName(product.product_name);
  //   setPrice(Math.round(product.product_price -
  //     product.product_price *
  //     (product.product_discount /
  //       100)));
  //   handleModalOpen();
  // };

  // const fetchData = async (page = 1) => {
  //   try {
  //     //   setLoading(true);
  //     const token = serverToken;
  //     const response = await axios.get(`${producturl}?page_size=40`, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     // console.log(response);
  //     setproduct(response.data.results);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const handleAdd = (product) => {
  //   dispatch(add(product));
  //   if (window.gtag_report_conversion) {
  //     window.gtag_report_conversion(undefined); // No redirection, just track conversion
  //   } else {
  //     console.error("gtag_report_conversion not found");
  //   }
  // };

  // const handleRemove = (product_id) => {
  //   dispatch(remove(product_id));
  // };

  // const scrollToElement = () => {
  //   const element = document.getElementById('explore-section');
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' }); // 'smooth' for a smooth scroll, can use 'auto' for instant scroll
  //   }
  // };

  // // Inside your component function
  // const isInCart = (productId) => {
  //   return products.some((item) => item.product_id === productId);
  // };

  // const [formData, setFormData] = useState({
  //   name: "",
  //   phone_number: "",
  //   email: "",
  //   product_name: productName,
  //   // description: '',
  //   address: "",
  // });
  // const [errors, setErrors] = useState({
  //   name: "",
  //   phone_number: "",
  //   email: "",
  //   // product_name: '',
  //   // description: '',
  //   address: "",
  // });
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   // Validation
  //   let formIsValid = true;
  //   const newErrors = {};
  //   Object.keys(formData).forEach((key) => {
  //     if (formData[key].trim() === "") {
  //       formIsValid = false;
  //       newErrors[key] = `${getFieldName(key)} is required`;
  //     }
  //   });

  //   if (!formIsValid) {
  //     setErrors(newErrors);
  //     setLoading(false);
  //     return;
  //   }

  //   try {
  //     const response = await fetch(
  //       "https://rashailagro-fnnh9.ondigitalocean.app/website/enquiry/",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formData),
  //       }
  //     );

  //     if (response.ok) {
  //       // console.log('Form submitted successfully');
  //       setFormData({
  //         name: "",
  //         phone_number: "",
  //         email: "",
  //         address: "",
  //       });
  //       handleModalClose();
  //     } else {
  //       console.error("Error submitting form");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const getFieldName = (fieldName) => {
  //   return fieldName
  //     .split("_")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  // const navigate = useNavigate();

  // const toComponentB = (product) => {
  //   navigate(`/shop/${encodeURIComponent(product.slug)}/`, {
  //     state: { product },
  //   });
  // };
  // const iotProducts =
  //   products &&
  //   products.filter((product) => product.product_category === "IOT Product");

  // const handlePaymentModalClose = () => {
  //   setShowPaymentModal(false);
  // };

  useEffect(() => { 
    window.location = "https://bazaar.rashailagro.in";
  }, []);

  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Rashail Agro: Innovative Products for Smart Farming</title>
                <meta name="description" content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more." />
                <base target="_blank" href="https://rashailagro.in/products/" />
                <link rel="canonical" href="https://rashailagro.in/products/" />
                <meta name="keywords" content="motor mobile, gsm motor, mobile starter, mobile motor starter, mobile auto starter, farm station, weather station, iot data logger, agriculture sensors, iot in agriculture, agri technology, agriculture tech, Smart iot devices, soil health, soil testing, farm health, precise farming, smart agriculture, farm equipments, farm machinery, advance farming, agriculture technology, precise farming, high yeild" />
                <meta property="og:title" content="Rashail Agro: Innovative Products for Smart Farming" />
                <meta property="og:description" content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more." />
                <meta property="og:url" content="https://rashailagro.in/products/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro: Innovative Products for Smart Farming" />
                <meta name="twitter:description" content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more." />
                <meta property="twitter:url" content="https://rashailagro.in/products/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      {/* <HeadProvider> */}
      {/* <Title>Rashail Agro: Innovative Products for Smart Farming</Title>
        <Meta
          name="description"
          content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more."
        />
        <base target="_blank" href="https://rashailagro.in/shop/" />
        <HeadLink rel="canonical" content="https://rashailagro.in/shop/" />
        <Meta
          name="keywords"
          content="motor mobile, gsm motor, mobile starter, mobile motor starter, mobile auto starter, automatic motor pump controller, 1.5 hp water pump motor price, 5 hp motor pump price, 1 hp motor pump price, solar motor pump price, three phase motor starter, motor starter connection, single phase motor starter, motor starter, gsm mobile, three phase starter, 3 phase starter, star delta starter, kisan solar pump yojana, texmo motor pump, drip irrigation and sprinkler irrigation, drip irrigation systems"
        />
        <Meta
          property="og:title"
          content="Rashail Agro: Innovative Products for Smart Farming"
        />
        <Meta
          property="og:description"
          content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more."
        />
        <Meta property="og:url" content="https://rashailagro.in/shop/" />
        <Meta property="og:type" content="website" />
        <Meta
          name="twitter:title"
          content="Rashail Agro: Innovative Products for Smart Farming"
        />
        <Meta
          name="twitter:description"
          content="Explore Rashail Agro's cutting-edge products for farm automation, weather monitoring, soil health analysis, and more."
        />
        <Meta property="twitter:url" content="https://rashailagro.in/shop/" />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div className="row Termconditionbackground d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center product_left_top">
              <h2><strong className="caerosaul_text_heading color_primary font_46 text-center hide_in_android">Future-Ready</strong><br /><strong className="caerosaul_text_heading hide_in_android">Tools for Every Farm</strong></h2>
              <span className="line_heading hide_in_android"></span>
              <p className="hide_in_android hide_in_android">Upgrade Your Agriculture with IoT, Automation, and Modern Machinery</p>
              <div className="d-md-flex mt-2 btn_container hide_in_android">
                <button
                  className="btn button_constraints mb-md-2 mb-2"
                  onClick={() => scrollToElement()}
                >
                  Explore Now
                </button>
              </div>
            </div>
            <div className="col-12 col-md-6 negative-margin">
              <div className="custom-carousel">
                <MyCarousel />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h1>
            <span className="privacy_heading ">Our </span>
            <span className="policy_heading"> Products</span>
          </h1>
          <h2 className="text-center border_new">
            Explore Our Range of Products Today!
          </h2>
        </div>
      </div>

      <div class="col-md-10 text-center justify-content-center  mt-3 mx-auto mb-4" id="explore-section">
        <h4 className="text-start">
          {" "}
          <span className="line_heading"></span>
          <span className="product_subheading">Smart</span>{" "}
          <span className="product_subheading_color">IoT Products</span>
        </h4>
      </div>
      {loading ? ( */}
      <div className="row Termconditionbackground d-flex align-items-center" style={{ "margin-top": "24rem" }}>
        <div className="loading-container" style={{ "margin-top": "2rem" }}>
          <img
            src={loading_image}
            alt="Loading"
            className="loading-image"
            width={200}
            height={200}
          />
        </div>
      </div>
      {/* ) : ( */}
      {/* <div className="row">
          {product.map((product) => {
            if (product.product_category === "IOT Product") {
              return (
                <div className="container mt-4">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-sm-10 col-12">
                      <div
                        className="card cardproduct_new mb-3"
                        style={{ border: "none" }}
                      >
                        <div className="row g-0">
                          <div
                            className="col-md-4 d-flex align-items-center justify-content-center"
                            onClick={() => {
                              toComponentB(product);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                src={product?.product_images[0]}
                                className="img-fluid rounded-start position-relative"
                                alt="Product"
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                  width: "100%",
                                  height: "auto",
                                  objectFit: "contain",
                                }}
                              />
                              {product.is_enquiry_product ? (
                                ""
                              ) : (
                                <span className="position-absolute top-0 start-0 translate-middle p-1 backgroundperoff text-white">
                                  {product.product_discount &&
                                    product.product_discount > 0
                                    ? `${product.product_discount}% OFF`
                                    : ""}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h5
                                className="card-title"
                                onClick={() => {
                                  toComponentB(product);
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  className="product_name"
                                  onClick={() => {
                                    toComponentB(product);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {product.product_name}
                                </span>
                                {product.is_top_selling ? (
                                  <span
                                    className="percent_off"
                                    onClick={() => {
                                      toComponentB(product);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Top selling
                                  </span>
                                ) : product.on_sale ? (
                                  <span
                                    className="percent_off_onsale"
                                    onClick={() => {
                                      toComponentB(product);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    On sale
                                  </span>
                                ) : (
                                  ""
                                )}
                              </h5>
                              <span
                                className="in_stock px-2"
                                onClick={() => {
                                  toComponentB(product);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {product.is_stock_available
                                  ? "In Stock"
                                  : "Out of Stock"}
                              </span>
                              <div
                                className="mt-2 product-card"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  toComponentB(product);
                                }}
                              >
                                <span className="productdescription text-start">
                                  {product.product_description.length > 160
                                    ? `${product.product_description.substring(
                                      0,
                                      160
                                    )}`
                                    : product.product_description}
                                  <span className="readmore">...More</span>
                                </span>
                                {product.is_enquiry_product ? (
                                  ""
                                ) : (
                                  <>
                                    <div>
                                      <div className="actualprice_product mx-2 text-start">
                                        ₹{Math.round(product.product_price -
                                          product.product_price *
                                          (product.product_discount /
                                            100))}
                                        <br />
                                      </div>
                                      <div className="prouct_price mx-2 text-start">
                                        <span className="fullprice_product">
                                          <small
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            ₹
                                            {product.product_price}
                                          </small>
                                        </span>
                                        <span className="percentoff_product mx-1">
                                          {product.product_discount
                                            ? `${product.product_discount}%`
                                            : "0%"}
                                        </span>
                                        <span className="percentoff_product_downarrow mx-1">
                                          ↓
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              {product.is_enquiry_product ? (
                                <div className="d-md-flex justify-content-end mt-2">
                                  <button
                                    className="btn buttonfunctionaity mb-md-2 mb-2"
                                    onClick={() => handleModalOpen1(product)}
                                  >
                                    Request a Demo
                                  </button>
                                </div>
                              ) : (
                                <div className="d-md-flex justify-content-end mt-2">
                                  {isInCart(product.product_id) ? (
                                    <button
                                      className="btn buttonfunctionaity1 mx-md-2 mb-2"
                                      onClick={() =>
                                        handleRemove(product.product_id)
                                      }
                                    >
                                      Remove from Cart
                                    </button>
                                  ) : (
                                    <button
                                      className="btn buttonfunctionaity1 mx-md-2 mb-2"
                                      onClick={() => handleAdd(product)}
                                    >
                                      Add to Cart
                                    </button>
                                  )}
                                  <button
                                    className="btn buttonfunctionaity mb-md-2 mb-2"
                                    onClick={() => handleBuyNow(product)}
                                  >
                                    Buy Now
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null; // Skip rendering if not in the desired category
          })}
        </div> */}
      {/* )} */}
      {/* {loading ? (
                <div className="loading-container">
                    <img src={loading_image} alt="Loading" className="loading-image" width={200} height={200} />
                </div>
            ) : (
                <>
                    {product && product.map((product, index) => {
                        return (
                            <div className="row d-flex align-items-center mt-3">
                                <div className="col-md-10 col-sm-10 justify-content-center  mx-auto">
                                    <div class="card cardproduct_new mb-3" style={{ border: "none" }}>
                                        <div class="row g-0">
                                            <div class="col-md-4 d-flex align-items-center justify-content-center " onClick={() => {
                                                toComponentB(product);
                                            }} style={{ cursor: "pointer" }}>

                                                <div style={{ position: "relative" }}>
                                                    <img src={product?.product_images[0]} class="img-fluid rounded-start position-relative" alt="Rashail - GSM Motor Controller 2G/4G" style={{ maxWidth: '200px', maxHeight: '200px', width: '100%', height: 'auto', objectFit: "contain" }} />

                                                    {product.is_enquiry_product ? (
                                                        ''
                                                    ) : (
                                                        <span className="position-absolute top-0 start-0 translate-middle p-1 backgroundperoff text-white">
                                                            {product.product_discount && product.product_discount > 0 ? `${product.product_discount}% OFF` : ''}
                                                        </span>
                                                    )}

                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body">
                                                    <h5 class="card-title " onClick={() => {
                                                        toComponentB(product);
                                                    }} style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <span className='product_name' onClick={() => {
                                                            toComponentB(product);
                                                        }} style={{ cursor: "pointer" }}>{product.product_name}</span>
                                                        {product.is_top_selling ? (
                                                            <span className='percent_off' onClick={() => {
                                                                toComponentB(product);
                                                            }} style={{ cursor: "pointer" }}>Top selling</span>
                                                        ) : (
                                                            product.on_sale ? (
                                                                <span className='percent_off_onsale' onClick={() => {
                                                                    toComponentB(product);
                                                                }} style={{ cursor: "pointer" }}>On sale</span>
                                                            ) : (
                                                                ''
                                                            )
                                                        )}
                                                    </h5>
                                                    <span className='in_stock px-2' onClick={() => {
                                                        toComponentB(product);
                                                    }} style={{ cursor: "pointer" }}>
                                                        {product.is_stock_available ? "In Stock" : "Out of Stock"}
                                                    </span>

                                                    <div className='mt-2 product-card ' style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => {
                                                        toComponentB(product);
                                                    }}>
                                                        <span className='productdescription'>
                                                            {product.product_description.length > 160 ?
                                                                `${product.product_description.substring(0, 160)}` :
                                                                product.product_description
                                                            }

                                                            <span className='readmore'>...More</span>

                                                        </span>

                                                        {product.is_enquiry_product ? (
                                                            ''
                                                        ) : (
                                                            <>
                                                                <div>
                                                                    <div className='actualprice_product mx-2'>₹{product.product_price}<br /></div>
                                                                    <div className='prouct_price mx-2'>
                                                                        <span className='fullprice_product'>
                                                                            <small style={{ textDecoration: "line-through" }}>
                                                                                ₹
                                                                                {product.product_price + (product.product_price * (product.product_discount / 100))}
                                                                            </small>
                                                                        </span>
                                                                        <span className='percentoff_product mx-1'>
                                                                            {product.product_discount ? `${product.product_discount}%` : '0%'}
                                                                        </span>
                                                                        <span className='percentoff_product_downarrow mx-1'>↓</span>
                                                                    </div>
                                                                </div>

                                                            </>

                                                        )}
                                                    </div>

                                                    {product.is_enquiry_product ? (
                                                        <div className="d-md-flex justify-content-end mt-2">
                                                            <button className="btn buttonfunctionaity mb-md-2 mb-2" onClick={() => {
                                                                toComponentB(product);
                                                            }}>
                                                                Demo Product
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="d-md-flex justify-content-end mt-2">
                                                            {isInCart(product.product_id) ? (
                                                                <button className="btn buttonfunctionaity1 mx-md-2 mb-2" onClick={() => handleRemove(product.product_id)}>
                                                                    Remove from Cart
                                                                </button>
                                                            ) : (
                                                                <button className="btn buttonfunctionaity1 mx-md-2 mb-2" onClick={() => handleAdd(product)}>
                                                                    Add to Cart
                                                                </button>
                                                            )}

                                                            <button className="btn buttonfunctionaity mb-md-2 mb-2"
                                                                onClick={() => handleBuyNow(product)}
                                                            >
                                                                Buy Now
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )} */}
      {/* <div class="col-md-10 text-center justify-content-center  mt-3 mx-auto mb-4">
        <h4 className="text-start">
          {" "}
          <span className="line_heading"></span>
          <span className="product_subheading">Our</span>{" "}
          <span className="product_subheading_color">Automation Products</span>
        </h4>
      </div> */}
      {
        // loading ? (
        //   <div className="loading-container">
        //     <img
        //       src={loading_image}
        //       alt="Loading"
        //       className="loading-image"
        //       width={200}
        //       height={200}
        //     />
        //   </div>
        // ) : (
        //   <div className="row">
        //     {product.map((product) => {
        //       if (product.product_category === "Automation Product") {
        //         return (
        //           <div className="container mt-4">
        //             <div className="row justify-content-center">
        //               <div className="col-md-10 col-sm-10 col-12">
        //                 <div
        //                   className="card cardproduct_new mb-3"
        //                   style={{ border: "none" }}
        //                 >
        //                   <div className="row g-0">
        //                     <div
        //                       className="col-md-4 d-flex align-items-center justify-content-center"
        //                       onClick={() => {
        //                         toComponentB(product);
        //                       }}
        //                       style={{ cursor: "pointer" }}
        //                     >
        //                       <div style={{ position: "relative" }}>
        //                         <img
        //                           src={product?.product_images[0]}
        //                           className="img-fluid rounded-start position-relative"
        //                           alt="Product"
        //                           style={{
        //                             maxWidth: "200px",
        //                             maxHeight: "200px",
        //                             width: "100%",
        //                             height: "auto",
        //                             objectFit: "contain",
        //                           }}
        //                         />
        //                         {product.is_enquiry_product ? (
        //                           ""
        //                         ) : (
        //                           <span className="position-absolute top-0 start-0 translate-middle p-1 backgroundperoff text-white">
        //                             {product.product_discount &&
        //                               product.product_discount > 0
        //                               ? `${product.product_discount}% OFF`
        //                               : ""}
        //                           </span>
        //                         )}
        //                       </div>
        //                     </div>
        //                     <div className="col-md-8">
        //                       <div className="card-body">
        //                         <h5
        //                           className="card-title"
        //                           onClick={() => {
        //                             toComponentB(product);
        //                           }}
        //                           style={{
        //                             display: "flex",
        //                             justifyContent: "space-between",
        //                           }}
        //                         >
        //                           <span
        //                             className="product_name"
        //                             onClick={() => {
        //                               toComponentB(product);
        //                             }}
        //                             style={{ cursor: "pointer" }}
        //                           >
        //                             {product.product_name}
        //                           </span>
        //                           {product.is_top_selling ? (
        //                             <span
        //                               className="percent_off"
        //                               onClick={() => {
        //                                 toComponentB(product);
        //                               }}
        //                               style={{ cursor: "pointer" }}
        //                             >
        //                               Top selling
        //                             </span>
        //                           ) : product.on_sale ? (
        //                             <span
        //                               className="percent_off_onsale"
        //                               onClick={() => {
        //                                 toComponentB(product);
        //                               }}
        //                               style={{ cursor: "pointer" }}
        //                             >
        //                               On sale
        //                             </span>
        //                           ) : (
        //                             ""
        //                           )}
        //                         </h5>
        //                         <span
        //                           className="in_stock px-2"
        //                           onClick={() => {
        //                             toComponentB(product);
        //                           }}
        //                           style={{ cursor: "pointer" }}
        //                         >
        //                           {product.is_stock_available
        //                             ? "In Stock"
        //                             : "Out of Stock"}
        //                         </span>
        //                         <div
        //                           className="mt-2 product-card"
        //                           style={{
        //                             display: "flex",
        //                             justifyContent: "space-between",
        //                             cursor: "pointer",
        //                           }}
        //                           onClick={() => {
        //                             toComponentB(product);
        //                           }}
        //                         >
        //                           <span className="productdescription text-start">
        //                             {product.product_description.length > 160
        //                               ? `${product.product_description.substring(
        //                                 0,
        //                                 160
        //                               )}`
        //                               : product.product_description}
        //                             <span className="readmore">...More</span>
        //                           </span>
        //                           {product.is_enquiry_product ? (
        //                             ""
        //                           ) : (
        //                             <>
        //                               <div>
        //                                 <div className="actualprice_product mx-2 text-start">
        //                                   ₹{Math.round(product.product_price -
        //                                     product.product_price *
        //                                     (product.product_discount /
        //                                       100))}
        //                                   <br />
        //                                 </div>
        //                                 <div className="prouct_price mx-2 text-start">
        //                                   <span className="fullprice_product">
        //                                     <small
        //                                       style={{
        //                                         textDecoration: "line-through",
        //                                       }}
        //                                     >
        //                                       ₹
        //                                       {Math.round(product.product_price)}
        //                                     </small>
        //                                   </span>
        //                                   <span className="percentoff_product mx-1">
        //                                     {product.product_discount
        //                                       ? `${product.product_discount}%`
        //                                       : "0%"}
        //                                   </span>
        //                                   <span className="percentoff_product_downarrow mx-1">
        //                                     ↓
        //                                   </span>
        //                                 </div>
        //                               </div>
        //                             </>
        //                           )}
        //                         </div>
        //                         {product.is_enquiry_product ? (
        //                           <div className="d-md-flex justify-content-end mt-2">
        //                             <button
        //                               className="btn buttonfunctionaity mb-md-2 mb-2"
        //                               onClick={() => handleModalOpen1(product)}
        //                             >
        //                               Request a Demo
        //                             </button>
        //                           </div>
        //                         ) : (
        //                           <div className="d-md-flex justify-content-end mt-2">
        //                             {isInCart(product.product_id) ? (
        //                               <button
        //                                 className="btn buttonfunctionaity1 mx-md-2 mb-2"
        //                                 onClick={() =>
        //                                   handleRemove(product.product_id)
        //                                 }
        //                               >
        //                                 Remove from Cart
        //                               </button>
        //                             ) : (
        //                               <button
        //                                 className="btn buttonfunctionaity1 mx-md-2 mb-2"
        //                                 onClick={() => handleAdd(product)}
        //                               >
        //                                 Add to Cart
        //                               </button>
        //                             )}
        //                             <button
        //                               className="btn buttonfunctionaity mb-md-2 mb-2"
        //                               onClick={() => handleBuyNow(product)}
        //                             >
        //                               Buy Now
        //                             </button>
        //                           </div>
        //                         )}
        //                       </div>
        //                     </div>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         );
        //       }
        //       return null; // Skip rendering if not in the desired category
        //     })}
        //   </div>
        // )
      }
      {/* {loading ? (
        <div className="loading-container">
          <img
            src={loading_image}
            alt="Loading"
            className="loading-image"
            width={200}
            height={200}
          />
        </div>
      ) : (
        <>
          <div class="col-md-10 text-center justify-content-center  mt-3 mx-auto">
            <h4 className="text-start">
              {" "}
              <span className="line_heading"></span>
              <span className="product_subheading">View</span>
              <span className="product_subheading_color">
                {" "}
                Other Products
              </span>{" "}
              <span className="product_subheading">From Us</span>
            </h4>
          </div>
          <div className="row">
            {product.map((product) => {
              if (product.product_category === "Other Product") {
                return (
                  <div className="container mt-4">
                    <div className="row justify-content-center">
                      <div className="col-md-10 col-sm-10 col-12">
                        <div
                          className="card cardproduct_new mb-3"
                          style={{ border: "none" }}
                        >
                          <div className="row g-0">
                            <div
                              className="col-md-4 d-flex align-items-center justify-content-center"
                              onClick={() => {
                                toComponentB(product);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div style={{ position: "relative" }}>
                                <img
                                  src={product?.product_images[0]}
                                  className="img-fluid rounded-start position-relative"
                                  alt="Product"
                                  style={{
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "contain",
                                  }}
                                />
                                {product.is_enquiry_product ? (
                                  ""
                                ) : (
                                  <span className="position-absolute top-0 start-0 translate-middle p-1 backgroundperoff text-white">
                                    {product.product_discount &&
                                      product.product_discount > 0
                                      ? `${product.product_discount}% OFF`
                                      : ""}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  onClick={() => {
                                    toComponentB(product);
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    className="product_name"
                                    onClick={() => {
                                      toComponentB(product);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {product.product_name}
                                  </span>
                                  {product.is_top_selling ? (
                                    <span
                                      className="percent_off"
                                      onClick={() => {
                                        toComponentB(product);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Top selling
                                    </span>
                                  ) : product.on_sale ? (
                                    <span
                                      className="percent_off_onsale"
                                      onClick={() => {
                                        toComponentB(product);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      On sale
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </h5>
                                <span
                                  className="in_stock px-2"
                                  onClick={() => {
                                    toComponentB(product);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {product.is_stock_available
                                    ? "In Stock"
                                    : "Out of Stock"}
                                </span>
                                <div
                                  className="mt-2 product-card"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    toComponentB(product);
                                  }}
                                >
                                  <span className="productdescription text-start">
                                    {product.product_description.length > 160
                                      ? `${product.product_description.substring(
                                        0,
                                        160
                                      )}`
                                      : product.product_description}
                                    <span className="readmore">...More</span>
                                  </span>
                                  {product.is_enquiry_product ? (
                                    ""
                                  ) : (
                                    <>
                                      <div>
                                        <div className="actualprice_product mx-2 text-start">
                                          ₹{Math.round(product.product_price -
                                            product.product_price *
                                            (product.product_discount /
                                              100))}
                                          <br />
                                        </div>
                                        <div className="prouct_price mx-2 text-start">
                                          <span className="fullprice_product">
                                            <small
                                              style={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              ₹
                                              {product.product_price}
                                            </small>
                                          </span>
                                          <span className="percentoff_product mx-1">
                                            {product.product_discount
                                              ? `${product.product_discount}%`
                                              : "0%"}
                                          </span>
                                          <span className="percentoff_product_downarrow mx-1">
                                            ↓
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {product.is_enquiry_product ? (
                                  <div className="d-md-flex justify-content-end mt-2">
                                    <button
                                      className="btn buttonfunctionaity mb-md-2 mb-2"
                                      onClick={() => handleModalOpen1(product)}
                                    >
                                      Request a Demo
                                    </button>
                                  </div>
                                ) : (
                                  <div className="d-md-flex justify-content-end mt-2">
                                    {isInCart(product.product_id) ? (
                                      <button
                                        className="btn buttonfunctionaity1 mx-md-2 mb-2"
                                        onClick={() =>
                                          handleRemove(product.product_id)
                                        }
                                      >
                                        Remove from Cart
                                      </button>
                                    ) : (
                                      <button
                                        className="btn buttonfunctionaity1 mx-md-2 mb-2"
                                        onClick={() => handleAdd(product)}
                                      >
                                        Add to Cart
                                      </button>
                                    )}
                                    <button
                                      className="btn buttonfunctionaity mb-md-2 mb-2"
                                      onClick={() => handleBuyNow(product)}
                                    >
                                      Buy Now
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </>
      )} */}
      {/* <Modal
        size="lg"
        show={showModal}
        onHide={handleModalClose}
        //  scrollable
        dialogClassName="custom-modal"
      >
        <Modal.Header
          className="mt-2"
          closeButton
          style={{ borderBottom: "none" }}
        >
          <Modal.Title className="text-center">Checkout</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="col-md-12 col-sm-12 text-center mx-auto">
            <div className="mt-3">
              <Buynowform
                productName={productName}
                Price={Price}
                onSubmit={handleSubmit}
                setShowModal={setShowModal}
                setShowPaymentModal={setShowPaymentModal}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <Modal
        show={showModal1}
        onHide={handleModalClose1}
      //  scrollable
      >
        <Modal.Header
          className="mt-2"
          closeButton
          style={{ borderBottom: "none" }}
        > */}
      {/* <Modal.Title className="text-center">Book your free demo </Modal.Title> */}
      {/* </Modal.Header>
        <Modal.Body className="text-center">
          <div className="col-md-10 col-sm-10 text-center mx-auto">
            <div className="mt-3">
              <Demoform
                closeModal1={handleModalClose1}
                productName={productName}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* Payment Confirm Modal */}
      {/* <Modal
        size="lg"
        show={showPaymentModal}
        onHide={handleModalClose}
        //  scrollable
        className="payment-confirm-modal"
        dialogClassName="payment-custom-modal"
      >
        <Modal.Header
          className="mt-2"
          closeButton
          style={{ borderBottom: "none" }}
        >
          <Modal.Title className="text-center">Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="col-md-12 col-sm-12 text-center mx-auto">
            <div className="mt-3 payment_status">
              <BsFillPatchCheckFill size={90} style={{ color: "#2fb95d" }} />
              <p>Payment successful</p>
            </div>
            <button
              className="confirm_modal"
              onClick={() => handlePaymentModalClose()}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <div className="emptydiv" style={{ height: "200px" }}></div> */}
    </div>
  );
};

export default Products;
