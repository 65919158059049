import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import reducer from "./Rootreducer";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: "root",
    version: 1,
    storage
};

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
    reducer: persistedReducer
});

export default store;