import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YouTubePlayer from "../Components/YouTubePlayer";
import YouTubePlayersblog from "../Components/YouTubePlayersblog";
import fasalamlogo from "../Images/blog/fasalamlogo.webp";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { blogdata, instablogdata } from "../Constants/Blogdata";
import { Title, Meta, HeadProvider, Link as HeadLink } from "react-head";
import { useDispatch, useSelector } from "react-redux";
import { videolist } from "../Redux/GetvideoSlice";
import { bloglist } from "../Redux/GetblogSlice";

const Blog = () => {
  const sliderRef = useRef(null);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);
  const [isHoveredExplore, setIsHoveredExplore] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);
  const videolists = useSelector((state) => state.video.data.results);
  const bloglists = useSelector((state) => state.blog.data.results);
  // console.log(bloglists)
  const dispatch = useDispatch();
  const Videodata = videolists || [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        await dispatch(videolist());
        await dispatch(bloglist());
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleHoverExplore = (index) => {
    setHoveredCard(index);
  };
  const goToPreviousSlide1 = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  const goToNextSlide1 = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const goToPreviousSlide = () => {
    if (sliderRef2.current) {
      sliderRef2.current.slickPrev();
    }
  };
  const goToNextSlide = () => {
    if (sliderRef2.current) {
      sliderRef2.current.slickNext();
    }
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 432,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const sliderSettings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const sliderSettings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: false,
    centerPadding: "50%",
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1.3,
        },
      },
      {
        breakpoint: 386, // New breakpoint at 375px
        settings: {
          slidesToShow: 1, // Adjust slidesToShow for screens <= 375px
        },
      },
    ],
  };

  const navigate = useNavigate();
  const toComponentB = (data) => {
    navigate(`/blog/${encodeURIComponent(data.slug)}/`, { state: { data } });
  };

  function truncateHtml(html, maxLength) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const textContent = doc.body.textContent || "";
    return textContent.length > maxLength
      ? `${textContent.substring(0, 40)}...`
      : textContent;
  }

  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Rashail Agro Blog: Latest Insights in Smart Farming</title>
                <meta name="description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <base target="_blank" href="https://rashailagro.in/blog/" />
                <link rel="canonical" href="https://rashailagro.in/blog/" />
                <meta name="keywords" content="smart farming tips, farming solutions, farming equipments, farming machinery, farming tools, agriculture sensors, iot in agriculture, smart farming solution, smart iot farming, blogs, agri revolution, farming revolution, youtube channel, instagram page, rashail agro, agritech startup, mp startup, success stories, farmers story" />
                <meta property="og:title" content="Rashail Agro Blog: Latest Insights in Smart Farming" />
                <meta property="og:description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <meta property="og:url" content="https://rashailagro.in/blog/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro Blog: Latest Insights in Smart Farming" />
                <meta name="twitter:description" content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog." />
                <meta property="twitter:url" content="https://rashailagro.in/blog/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      <HeadProvider>
        <Title>Rashail Agro Blog: Latest Insights in Smart Farming</Title>
        <Meta
          name="description"
          content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog."
        />
        <base target="_blank" href="https://rashailagro.in/blog/" />
        <HeadLink rel="canonical" content="https://rashailagro.in/blog/" />
        <Meta
          name="keywords"
          content="smart farming tips, farming solutions, farming equipments, farming machinery, farming tools, agriculture sensors, iot in agriculture, smart farming solution, smart iot farming, blogs, agri revolution, farming revolution, youtube channel, instagram page, rashail agro, agritech startup, mp startup, success stories, farmers story"
        />
        <Meta
          property="og:title"
          content="Rashail Agro Blog: Latest Insights in Smart Farming"
        />
        <Meta
          property="og:description"
          content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog."
        />
        <Meta property="og:url" content="https://rashailagro.in/blog/" />
        <Meta property="og:type" content="website" />
        <Meta
          name="twitter:title"
          content="Rashail Agro Blog: Latest Insights in Smart Farming"
        />
        <Meta
          name="twitter:description"
          content="Discover trends, technology, and expert advice on smart farming and precision agriculture on the Rashail Agro blog."
        />
        <Meta property="twitter:url" content="https://rashailagro.in/blog/" />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div className="row Termconditionbackground d-flex align-items-center">
        <div
          animationIn="fadeInDownBig"
          className="col-md-11 col-sm-12 text-center mx-auto"
        >
          <h1 className="mt-5 ">
            <span className="privacy_heading ">Our </span>
            <span className="policy_heading"> Blog</span>
          </h1>
          <h2 className="text-center border_new">
            Cultivating Abundance: Insights and Tips for Thriving Farms on Our
            Blog
          </h2>
        </div>
      </div>
      <div className="row  mt-3">
        <div animationIn="bounce" className="col-md-8 text-center">
          <h2>
            <span className="policy_heading"> Explore</span>
            <span className="privacy_heading "> Our Blog </span>
            <span className="policy_heading"> for captivating stories</span>
          </h2>
        </div>
        <div className="col-md-4 col-md-4 text-center d-flex align-items-center justify-content-center">
          <div className="viewall_blog">
            <RouterLink
              to="/blog/all-blogs/"
              style={{ textDecoration: "none", color: "#2FB95D" }}
            >
              <span className="mt-2">View All</span>
            </RouterLink>
          </div>
        </div>
      </div>
      <div className="row mb-5 client-feedback-mobile ">
        <div className="col-md-11 text-center mx-auto mt-3">
          <div className="testimonial-slider-container ">
            <Slider ref={sliderRef} {...sliderSettings}>
              {bloglists &&
                bloglists.slice(0, 4).map((data, index) => (
                  <div key={index} className="testimonial-slide">
                    <div
                      className="card custom-card-style_blog"
                      style={{ width: "20rem" }}
                    >
                      <div className="card_blog_first">
                        <img
                          src={data.image}
                          alt={`Blog Image ${index}`}
                          className="img-fluid all-blog_img"
                        />
                        <div className="card-body">
                          <h2 className="card-title card_home_heading text-start">
                            {" "}
                            {data.title.length > 15
                              ? `${data.title.substring(0, 15)}...`
                              : data.title}
                            {data.title.length > 15}
                          </h2>
                          {data.content && (
                            <p
                              className="card-text faq_answer text-start"
                              dangerouslySetInnerHTML={{
                                __html: truncateHtml(data.content, 40),
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <span
                        className={`readmore_blog text-center mt-2 ${
                          hoveredCard === index ? "show-arrow" : ""
                        }`}
                        onMouseEnter={() => handleHoverExplore(index)}
                        onMouseLeave={() => handleHoverExplore(null)}
                        onClick={() => {
                          toComponentB(data);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {hoveredCard === index ? (
                          <>
                            Read more <FontAwesomeIcon icon={faArrowRight} />
                          </>
                        ) : (
                          "Read more"
                        )}
                      </span>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
        <div className="col-md-11 justify-content-end">
          <div className="d-flex justify-content-end mx-2 mb-3">
            <button
              className="circular-button mx-2"
              onClick={goToPreviousSlide1}
            >
              <i className="fa fa-chevron-left" />
            </button>
            <button className="circular-button mx-2" onClick={goToNextSlide1}>
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        </div>
      </div>
      {/* Video section ---------------- */}
      <div className="row justify-content-center text-center mx-auto mb-5">
        <p className="heading_home">
          Check out our{" "}
          <span className="heading_home_color">video highlights</span>
        </p>
        {/* Single video in col-10 */}
        <div className="col-md-10">
          <div className="position-relative">
            {Videodata.length > 0 && (
              <div className="position-relative">
                <YouTubePlayer videoId={Videodata[0].link} />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-md-3">
          <Slider {...sliderSettings1}>
            {Videodata.slice(1, 6).map((video, index) => (
              <YouTubePlayersblog key={index} videoId={video.link} />
            ))}
          </Slider>
        </div>
      </div>
      <div
        className="row justify-content-center text-center  mb-5 Farming_community"
        style={{ position: "relative" }}
      >
        <p className="heading_blog mt-3">
          Explore Our Thriving Farming Community
        </p>
        <div
          className="slider-container"
          style={{ position: "absolute", bottom: -120 }}
        >
          <Slider ref={sliderRef2} {...sliderSettings2}>
            {instablogdata.map((data, index) => (
              <div
                key={index}
                className="testimonial-slide  testimonialthreesixfive"
              >
                <a
                  href={data.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="card card_blog_first cartthreesixfive"
                    style={{ maxWidth: "30rem" }}
                  >
                    <div className="mt-2">
                      <div className="row">
                        <div className="col-md-6 d-flex mx-1">
                          <div className="mx-2">
                            <img
                              src={fasalamlogo}
                              className="img-fluid rounded-circle"
                              style={{ width: "36px", height: "36px" }}
                              alt="fasalam-logo"
                            />
                          </div>
                          <div className="ms-1">
                            <div className="text-start sonu_color">
                              {data.name}
                            </div>
                            <div className="text-start location_color">
                              {data.city}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="ms-1 mt-4">
                            <div className="text-start location_color">
                              {data.date}
                              <span
                                className="ms-1 sonu_color"
                                style={{ fontSize: "10px" }}
                              >
                                {data.farm}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <img
                          src={data.img}
                          alt={`Blog Image ${index}`}
                          className="img-fluid img_community"
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="row mt-2">
                        <div className="d-flex mx-auto">
                          <div className="col-md-4 ">
                            <div className="mx-2 d-flex">
                              <span className="mx-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="23"
                                  viewBox="0 0 22 23"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_2396_6116)">
                                    <path
                                      d="M20.3405 4.27022C17.1262 0.0971134 10.8004 2.55951 10.8004 6.79741C10.8004 2.55951 4.47425 0.096911 1.25952 4.27022C-2.06299 8.58457 1.21178 16.1422 10.8002 20.4836C20.3882 16.1422 23.663 8.58457 20.3405 4.27022Z"
                                      fill="#9FAFA4"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2396_6116">
                                      <rect
                                        width="21.6"
                                        height="21.6"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="like_blog">Like</p>
                              </span>
                              <span className="mx-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="23"
                                  viewBox="0 0 23 23"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_2396_6276)">
                                    <path
                                      d="M18.5214 3.51931C16.5298 1.92575 14.051 1.06517 11.5003 1.08178C5.65573 1.08178 0.917969 5.41676 0.917969 10.7642C0.917969 10.7903 0.917969 10.8169 0.91862 10.843C0.917969 10.8553 0.917969 10.867 0.917969 10.8794C0.918667 11.7373 1.05325 12.5897 1.31685 13.4061C1.32206 13.4236 1.32791 13.4419 1.33377 13.4601C1.7637 14.8019 2.48877 16.0304 3.45571 17.0552C3.61946 17.7778 3.59815 18.5301 3.39374 19.2423C3.18934 19.9544 2.80846 20.6035 2.2864 21.1293C2.25452 21.1611 2.23279 21.2017 2.22396 21.2459C2.21514 21.29 2.21961 21.3359 2.23681 21.3775C2.25401 21.4191 2.28317 21.4548 2.3206 21.4798C2.35803 21.5049 2.40206 21.5183 2.44712 21.5184C4.19179 21.5116 5.86708 20.8344 7.12632 19.6268L7.15755 19.5969C8.53552 20.1619 10.011 20.4509 11.5003 20.4473C14.7239 20.4473 17.6104 19.1284 19.5515 17.0481C21.166 15.3531 22.0716 13.1051 22.0827 10.7642C22.0827 7.88162 20.7058 5.29313 18.5214 3.51931Z"
                                      fill="#9FAFA4"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2396_6276">
                                      <rect
                                        width="21.6"
                                        height="21.6"
                                        fill="white"
                                        transform="translate(0.701172 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="like_blog">Comment</p>
                              </span>
                              <span className="mx-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="23"
                                  viewBox="0 0 38 39"
                                  fill="none"
                                >
                                  <path
                                    d="M26.8395 12.0013C26.8395 12.0013 28.5879 11.3196 28.4422 12.9753C28.3936 13.6571 27.9565 16.0432 27.6166 18.6241L26.4509 26.2695C26.4509 26.2695 26.3538 27.3895 25.4796 27.5843C24.6054 27.7791 23.2942 26.9026 23.0513 26.7077C22.857 26.5617 19.4088 24.3703 18.1947 23.299C17.8547 23.0068 17.4662 22.4224 18.2432 21.7407L23.3427 16.871C23.9255 16.2867 24.5083 14.9232 22.08 16.5788L15.2807 21.205C15.2807 21.205 14.5036 21.692 13.0467 21.2537L9.88982 20.2798C9.88982 20.2798 8.72422 19.5494 10.7155 18.8189C15.5721 16.5301 21.5458 14.1927 26.8395 12.0013Z"
                                    fill="#9FAFA4"
                                  />
                                </svg>
                                <p className="like_blog">Share</p>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4 mx-auto ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="54"
                              height="10"
                              viewBox="0 0 54 10"
                              fill="none"
                            >
                              <circle cx="4.5" cy="5" r="4.5" fill="#2FB95D" />
                              <circle cx="19.5" cy="5" r="4.5" fill="#9FAFA4" />
                              <circle cx="34.5" cy="5" r="4.5" fill="#9FAFA4" />
                              <circle cx="49.5" cy="5" r="4.5" fill="#9FAFA4" />
                            </svg>
                          </div>
                          <div className="col-md-4  mx-auto">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M21.4844 4.29462C21.4844 3.50944 21.1784 2.75719 20.6344 2.20256C20.0851 1.64156 19.3392 1.32812 18.5625 1.32812H7.9375C7.16081 1.32812 6.41494 1.64156 5.86563 2.20256C5.32163 2.75719 5.01562 3.50944 5.01562 4.29462C5.01562 8.38844 5.01562 18.0551 5.01562 22.2902C5.01562 23.0254 5.4385 23.6906 6.09194 23.9966C6.756 24.3089 7.53694 24.1984 8.09156 23.7139L13.0832 19.3449C13.1788 19.261 13.3212 19.261 13.4168 19.3449L18.4084 23.7139C18.9631 24.1984 19.744 24.3089 20.4081 23.9966C21.0615 23.6906 21.4844 23.0254 21.4844 22.2902V4.29462Z"
                                  fill="#9FAFA4"
                                />
                              </svg>
                              <p className="like_blog">Save</p>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <h2 className=" blog_que text-start">
                          {/* {data.heading} */}
                          {data.description.length > 40
                            ? `${data.heading.substring(0, 40)}...`
                            : data.heading}
                          {data.heading.length > 40}
                        </h2>
                        <p className="blog_answer text-start">
                          {data.description.length > 50
                            ? `${data.description.substring(0, 50)}...`
                            : data.description}
                          {data.description.length > 50}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </Slider>
          <button
            className="circular-button mx-2 mt-2"
            onClick={goToPreviousSlide}
          >
            <i className="fa fa-chevron-left" />
          </button>
          <button className="circular-button mx-2 mt-2" onClick={goToNextSlide}>
            <i className="fa fa-chevron-right" />
          </button>
        </div>
      </div>
      {/* <div className="container">
                <div className="row justify-content-center forming_hub">
                    <div className="col-md-6">
                        <p className='heading_home_color text-center'>Add Your Post <span className='heading_home'>to our Farming Hub!</span></p>
                        <form>
                            <div className="mb-3">
                                <input
                                    type="file"
                                    className={`form-control inputWithBorder`}
                                    placeholder="Upload Photo/Video"
                                    required=""
                                    name="uploadFile"
                                />
                            </div>
                            <div className="mb-3">
                                <select
                                    className="form-select blue-background selectcategory_blog"
                                    name="delivery"
                                >
                                    <option value="Select Category selectcategory_blog">Select Category</option>
                                    <option value="category1">Category 1</option>
                                    <option value="category2">Category 2</option>
                                    <option value="category3">Category 3</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control addheadline_blog"
                                    placeholder="Add Headline"
                                    required=""
                                    name="mobileno"
                                />
                            </div>
                            <div className="mb-3">
                                <textarea
                                    type="text"
                                    className="form-control description_blog"
                                    rows={6}
                                    name="comment"
                                    placeholder='Write a Description'
                                />
                            </div>
                            <div className="mb-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary submitreview_blog"
                                    style={{ width: '100%' }}>
                                    Submit for Review
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
      <div className="emptydiv " style={{ height: "250px" }}></div>
    </div>
  );
};

export default Blog;
