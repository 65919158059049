import { combineReducers } from "redux";
import GetvideoReducer from "../Redux/GetvideoSlice"
import GetblogReducer from "../Redux/GetblogSlice"
import cartReducer from "../Redux/cartSlice"


const reducer = combineReducers({
    // auth: authReducer,
    video:GetvideoReducer,
    blog:GetblogReducer,
    cart: cartReducer,
});

export default reducer;