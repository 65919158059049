import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IOTproduct } from "../Constants/Homedata";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { producturl, serverToken } from "../Constants/urls";

const Iotproduct = () => {
  const sliderRef3 = useRef(null);
  const [product, setproduct] = useState();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [isHoveredExplore, setIsHoveredExplore] = useState(false);
  const handleHoverExplore = (index) => {
    setIsHoveredExplore(!isHoveredExplore);
    setHoveredCard(index);
  };

  const goToPreviousSlide3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.slickPrev();
    }
  };

  const goToNextSlide3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.slickNext();
    }
  };

  const sliderSettings3 = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 542,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 432,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const fetchData = async (page = 1) => {
    try {
      //   setLoading(true);
      const token = serverToken;
      const response = await axios.get(producturl, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      // console.log(response);
      setproduct(response.data.results);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const toComponentB = (product) => {
    const newUrl = `/shop/${encodeURIComponent(product.slug)}`;
    navigate(newUrl, { state: { product } });
    window.location.replace(newUrl);
  };

  return (
    <div className="row mt-5 mb-2 justify-content-center">
      <div className="col-md-10 text-center">
        <h4>
          {" "}
          <span className="line_heading"></span>Our IoT Powered Products
        </h4>
        <h2 className="heading_home">
          Do Check Our{" "}
          <span className="heading_home_color">IoT Powered Products</span>
        </h2>
      </div>
      <div className="col-md-10 text-center mt-3">
        <div className="testimonial-slider-container ">
          <Slider ref={sliderRef3} {...sliderSettings3}>
            {product &&
              product.map((product, index) => {
                return (
                  <div className="testimonial-slide testimonialthreesixfive">
                    <div
                      className="card custom-card-style_blog cartthreesixfive"
                      style={{ width: "20rem" }}
                    >
                      <div
                        className="card_blog_first "
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={product?.product_images[0]}
                          alt={`Blog Image`}
                          className="img-fluid"
                          style={{
                            // maxWidth: '300px',
                            height: "150px",
                            display: "block",
                            margin: "0 auto",
                            objectFit: "contain",
                            padding: "20px",
                          }}
                        />
                        <div
                          className="card-body"
                          style={{ width: "100%", height: "100px" }}
                        >
                          <h5 className="card-title card_home_heading text-center">
                            {product.product_name}
                          </h5>
                        </div>
                      </div>
                      <span
                        className={`readmore_blog text-center mt-2 ${
                          hoveredCard === index ? "show-arrow" : ""
                        }`}
                        onMouseEnter={() => handleHoverExplore(index)}
                        onMouseLeave={() => handleHoverExplore(null)}
                        onClick={() => {
                          toComponentB(product);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {hoveredCard === index ? (
                          <>
                            {/* {product.product_name} */}
                            {product.product_name.length > 15
                              ? `${product.product_name.substring(0, 15)}...`
                              : product.product_name}
                            {product.product_name.length > 15}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </>
                        ) : (
                          <>
                            {product.product_name.length > 15
                              ? `${product.product_name.substring(0, 15)}...`
                              : product.product_name}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
      <div className="col-md-10 mt-3 ">
        <div className="d-flex justify-content-center mb-3">
          <span className="">
            <span className="">
              <button
                className="circular-button mx-2"
                onClick={goToPreviousSlide3}
              >
                <i className="fa fa-chevron-left" />
              </button>
              <button className="circular-button mx-2" onClick={goToNextSlide3}>
                <i className="fa fa-chevron-right" />
              </button>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Iotproduct;
