import React from "react";
import { Helmet } from "react-helmet";
import { Title, Meta, HeadProvider, Link } from "react-head";

const Privacypolicy = () => {
  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Rashail Agro Privacy Policy</title>
                <meta name="description" content="Learn how Rashail Agro safeguards your data while providing smart farming solutions, IoT integration, and AI-powered agriculture tools." />
                <base target="_blank" href="https://rashailagro.in/privacy-policy/" />
                <link rel="canonical" href="https://rashailagro.in/privacy-policy/" />
                <meta name="keywords" content="rashail agro, privacy policy, data collection, farmers data" />
                <meta property="og:title" content="Rashail Agro Privacy Policy" />
                <meta property="og:description" content="Learn how Rashail Agro safeguards your data while providing smart farming solutions, IoT integration, and AI-powered agriculture tools." />
                <meta property="og:url" content="https://rashailagro.in/privacy-policy/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rashail Agro Privacy Policy" />
                <meta name="twitter:description" content="Learn how Rashail Agro safeguards your data while providing smart farming solutions, IoT integration, and AI-powered agriculture tools." />
                <meta property="twitter:url" content="https://rashailagro.in/privacy-policy/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      <HeadProvider>
        <Title>Rashail Agro Privacy Policy</Title>
        <Meta
          name="description"
          content="Learn how Rashail Agro safeguards your data while providing smart farming solutions, IoT integration, and AI-powered agriculture tools."
        />
        <base target="_blank" href="https://rashailagro.in/privacy-policy/" />
        <Link
          rel="canonical"
          content="https://rashailagro.in/privacy-policy/"
        />
        <Meta
          name="keywords"
          content="rashail agro, privacy policy, data collection, farmers data"
        />
        <Meta property="og:title" content="Rashail Agro Privacy Policy" />
        <Meta
          property="og:description"
          content="Learn how Rashail Agro safeguards your data while providing smart farming solutions, IoT integration, and AI-powered agriculture tools."
        />
        <Meta
          property="og:url"
          content="https://rashailagro.in/privacy-policy/"
        />
        <Meta property="og:type" content="website" />
        <Meta name="twitter:title" content="Rashail Agro Privacy Policy" />
        <Meta
          name="twitter:description"
          content="Learn how Rashail Agro safeguards your data while providing smart farming solutions, IoT integration, and AI-powered agriculture tools."
        />
        <Meta
          property="twitter:url"
          content="https://rashailagro.in/privacy-policy/"
        />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div className="row privacytopbackground d-flex align-items-center">
        <div
          animationIn="fadeInDownBig"
          className="col-md-11 col-sm-12 mx-auto "
        >
          <h1 className="mt-5 ">
            <span className="privacy_heading">Privacy</span>
            <span className="policy_heading"> Policy</span>
          </h1>
          <p className="text-start border_new">Last Updated Jan 24, 2024</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <p className="paraheading mt-4 mb-5 text-start">
            This privacy statement is written to inform our visitors of how we
            operate. We would like to inform you that whenever you browse our
            website, we will take all reasonable precautions to keep the
            information that we gather as secure as we can.
          </p>
          <span>
            <h2 className="mainheading_terms text-start">
              1. User’s Assent To The Privacy Statement
            </h2>
            <p className="paraheading mt-4 mb-5 text-start">
              You consent to letting us have access to your information if you
              abide by our privacy statement. Likewise, let us distribute the
              information however we see fit. The privacy statement is subject
              to periodic revisions. In order to see the updated website, please
              visit us frequently.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">2. Data Collection</h2>
            <p className="paraheading mt-4 mb-5 text-start">
              According to our privacy policy, we gather user data at various
              points during a visitor’s visit to our website. These points are
              shown below.
            </p>
            <h2 className="paraheading-career-apply text-start">
              Opting Our Services
            </h2>
            <p className="paraheading mt-4 mb-5 text-start">
              Users need to provide their contact information, including name,
              email address, contact number, location, and area of interest,
              when they inquire about our services or sign up for our services.
            </p>
            <h2 className="paraheading-career-apply text-start">
              Back End Data Collection
            </h2>
            <p className="paraheading mt-4 mb-5 text-start">
              When a user visits our website, we collect data about that user.
              Additionally, we keep track of each and every activity a visitor
              takes while on our site. We want to offer our users the greatest
              possible user experience.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              3.Purpose of Data Collection
            </h2>
            <p className="paraheading mt-4 mb-3 text-start">
              In order to provide our visitors with better services, we collect
              and store user data for our partners. Here’s a quick summary of
              why we gather user data:
            </p>
            <p className="paraheading text-start">
              <span className="checkbox"></span> The first and most important
              step is to confirm the user’s identification who fills out our
              website’s contact form or contacts us via live chat or through
              email subscription.
            </p>
            <p className="paraheading text-start">
              <span className="checkbox"></span> To advertise, do statistical
              research, conduct client studies, and create client profiles; to
              provide better services to our users.
            </p>
            <p className="paraheading text-start">
              <span className="checkbox"></span> Analyse market developments and
              demand.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              4.Data processing Best Practices
            </h2>
            <p className="paraheading mt-4 mb-5 text-start">
              In compliance with data protection laws, we process user data.
              Processing our data is necessary for us to offer quality services
              to our users. We will only process data in accordance with our
              policy when the user agrees to the conditions of it. Additionally,
              users have the option to contact us at any time to revoke their
              consent to receive our data.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">5.Data Exchange</h2>
            <p className="paraheading mt-4 mb-3 text-start">
              We will only share the data we have collected when it is
              absolutely necessary. Here are the situations in which we share
              user data with a third party or an individual.
            </p>
            <p className="paraheading text-start">
              <span className="checkbox"></span> Our business partners who
              provide services to our customers
            </p>
            <p className="paraheading text-start">
              <span className="checkbox"></span> If a government associate or
              regulatory authority requested it, we need to share the required
              information.
            </p>
            <p className="paraheading text-start">
              <span className="checkbox"></span> If there is a legal process
              that we must serve, we must share data.
            </p>
            <p className="paraheading text-start">
              <span className="checkbox"></span>With the user’s consent, we will
              share data for marketing purposes as long as the user does not
              object.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              6.Maintaining Data Confidentially
            </h2>
            <p className="paraheading mt-4 mb-5 text-start">
              In order to maintain confidentiality, we take effective measures
              to secure user data. We will not allow any individual or third
              party to access our users’ private information in accordance with
              our policy. We utilised advanced technology, such as a better
              server and a larger database, to accomplish this.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">
              7.Collecting Browser Cookies
            </h2>
            <p className="paraheading mt-4 mb-5 text-start">
              In order to maintain confidentiality, We take effective measures
              to secure user data. We will not allow any individual or third
              party to access our users’ private information in accordance with
              our policy. We utilised advanced technology, such as a better
              server and a larger database, to accomplish this.
            </p>
          </span>
          <span>
            <h2 className="mainheading_terms text-start">8.Contact Us</h2>
            <p className="paraheading mt-4 mb-5 text-start">
              If you’d like to learn more about our privacy policies, we’d be
              happy to provide you with more information on how we protect and
              use your personal information. Contact Support for more
              information.
            </p>
          </span>
        </div>
      </div>
      <div className="emptydiv" style={{ height: "150px" }}></div>
    </div>
  );
};

export default Privacypolicy;
