import React from 'react';
import YouTube from 'react-youtube';

const YouTubePlayersblog = ({ videoId }) => {
    const opts = {
        height: '200vw', // 100% of the viewport width for a very tall aspect ratio
        width: '90%', 
        playerVars: {
            autoplay: 0,
        },
        playerStyle: {
            borderRadius: '30px',
        },
    };

    const mobileOpts = {
        width: '90%', // Adjust the width for mobile view
        height: '60vw', // Adjust the height for mobile view
        playerVars: {
            autoplay: 0,
        },
        playerStyle: {
            borderRadius: '10px', // Adjust the border radius for mobile view
        },
    };
    

    

    return (
        <div className="smallvideo" >
             {window.innerWidth > 500 ? (
                <YouTube videoId={videoId} opts={opts} />
            ) : (
                <YouTube videoId={videoId} opts={mobileOpts} />
            )}
        </div>
    );
};

export default YouTubePlayersblog;
