import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

// Modal Component
const Modal = ({ children, onClose }) => {
    return (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body position-relative">
                        {/* Close Button */}
                        <button
                            type="button"
                            className="btn-close position-absolute top-0 end-0 m-3"
                            onClick={onClose}
                            aria-label="Close"
                        ></button>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Gallery Component
const GalleryComponent = ({ images = [] }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openModal = (index) => {
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowRight') {
            handleNext();
        } else if (e.key === 'ArrowLeft') {
            handlePrev();
        } else if (e.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    return (
        <div className="row g-2">
            {images.slice(0, 6).map((img, index) => (
                <div
                    key={index}
                    className="col-6 col-md-3 position-relative"
                    style={{ maxHeight: '200px', overflow: 'hidden' }}
                    onClick={() => openModal(index)}
                >
                    <img
                        src={img}
                        alt={`Thumbnail ${index + 1}`}
                        className="img-fluid rounded"
                        style={{ height: '200px', objectFit: 'cover', borderRadius: '4px' }}
                    />
                </div>
            ))}

            {/* Display remaining images count if there are more than 6 images */}
            {images.length > 6 && (
                <div
                    className="col-6 col-md-3 position-relative d-flex align-items-center justify-content-center"
                    style={{ maxHeight: '150px', cursor: 'pointer', overflow: 'hidden' }}
                    onClick={() => openModal(6)}
                >
                    <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex align-items-center justify-content-center">
                        <span className="text-white fs-5 fw-bold">+{images.length - 6} more</span>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <Modal onClose={closeModal}>
                    <div className="d-flex flex-column align-items-center">
                        {/* Large Image Display */}
                        <div className="position-relative mb-4 d-flex align-items-center">
                            <button
                                onClick={handlePrev}
                                className="btn btn-dark bg-opacity-50 position-absolute start-0 m-2"
                            >
                                &lt;
                            </button>
                            <div className="text-center" style={{ maxHeight: '500px', overflow: 'hidden' }}>
                                <img
                                    src={images[currentIndex]}
                                    alt={`Image ${currentIndex + 1}`}
                                    className="img-fluid"
                                    style={{ maxWidth: '800px', maxHeight: '500px', objectFit: 'cover' }}
                                />
                            </div>
                            <button
                                onClick={handleNext}
                                className="btn btn-dark bg-opacity-50 position-absolute end-0 m-2"
                            >
                                &gt;
                            </button>
                        </div>

                        {/* Thumbnail Gallery */}
                        <div className="d-flex flex-nowrap overflow-auto mt-4 overflow-y-hidden">
                            {images.map((img, idx) => (
                                <img
                                    key={idx}
                                    src={img}
                                    alt={`Thumbnail ${idx + 1}`}
                                    className={`img-thumbnail me-2 ${currentIndex === idx ? 'border border-primary' : ''}`}
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        cursor: 'pointer',
                                        objectFit: 'cover',
                                        transform: currentIndex === idx ? 'scale(1.1)' : 'scale(1)',
                                        transition: 'transform 0.2s',
                                    }}
                                    onClick={() => handleThumbnailClick(idx)}
                                />
                            ))}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default GalleryComponent;
