const isProd = true;
const serverToken = isProd ? "0652edcff65faf5e95f80477a97009ffc78ec963" : "c251eae6f77a1f043cff009a256914cee7aee6cd	";
const base_url = isProd ? `https://prod.rashailagro.in` : `https://preprod.dashboard.rashailagro.in`;
const producturl = `${base_url}/shop/product-view`
const allblog = `${base_url}/website/blog-detail`
const likeurl = `${base_url}/website/blog-like-view/`
const commenturl = `${base_url}/website/blog-comment-view/`
const videourl = `${base_url}/website/web-youtube/`
const web_crud_url = `${base_url}/shop/web_order/`
const verify_url = `${base_url}/shop/verify_payment_web/`
const weborder_url = `${base_url}/shop/create_order_web/`
const enquiryurl = `${base_url}/website/enquiry/`
export const forumUrl = `${base_url}/community/forum-global-view/`;
export const postUrl = `${base_url}/community/post-global-view/`;
export const shopCaerosaul = `${base_url}/shop/shop-carousel`;


export {
  base_url,
  allblog,
  producturl,
  likeurl,
  commenturl,
  videourl,
  weborder_url,
  enquiryurl,
  serverToken,
  verify_url,
  web_crud_url
};