import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import iosqr from "../Images/Home/iosqr.webp";
import modelimg from "../Images/Navbar/_7c62f09d-76d8-4dbe-bd26-25fc6ca32054 1.webp";
import { Helmet } from "react-helmet";
import { Title, Meta, HeadProvider, Link } from "react-head";
import qrforandroid from "../Images/Home/qr_forandroid.png";
import satelliteTree from "../Images/Satellite/satelliteTree.png";
import BlogGrid from "../Components/cards/CardGrid";
import { satelliteCardsData } from "../utils/satelliteCardData";
import SimpleBlogGrid from "../Components/cards/SimpleGrid";
import { revolutionizeBlogData } from "../utils/satelliteCardData";
import satelliteMap from "../Images/Satellite/sateliteeMap.png";
import satellite from "../Images/Satellite/satellite.png";
import { useNavigate } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import satelliteGroup from "../Images/Satellite/satelliteGroup.png";
import satelliteGroup1 from "../Images/Satellite/SatelliteGroup1.png";
import SatelliteGroup2 from "../Images/Satellite/satelliteGroup2.png";
import SatelliteGroup3 from "../Images/Satellite/satelliteGroup3.png";

const Satelite = () => {
  const navigate = useNavigate();
  const [showModal1, setShowModal1] = useState(false);

  const handleModalClose1 = () => {
    setShowModal1(false);
    // setShowApplicationForm(false);
  };

  const handleModalOpen1 = () => {
    setShowModal1(true);
    window.dataLayer.push({ event: "button-click" });
  };

  const handleDownloadClick = () => {
    window.open("https://apps.apple.com/in/app/fasalam/id6478108954", "_blank");
  };

  const handleDownloadClick_android = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.rashailagro.fasalam",
      "_blank"
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.getElementById("imageContainer").classList.add("animate");
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container-fluid">
      {/* <Helmet>
                <title>Fasalam App: Your All-in-One Smart Farming Tool</title>
                <meta name="description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam mobile app by Rashail Agro." />
                <base target="_blank" href="https://rashailagro.in/satellite/" />
                <link rel="canonical" href="https://rashailagro.in/satellite/" />
                <meta property="og:title" content="Fasalam App: Your All-in-One Smart Farming Tool" />
                <meta property="og:description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam mobile app by Rashail Agro." />
                <meta property="og:url" content="https://rashailagro.in/satellite/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Fasalam App: Your All-in-One Smart Farming Tool" />
                <meta name="twitter:description" content="Manage your farm, get real-time data, and explore market trends with the Fasalam mobile app by Rashail Agro." />
                <meta property="twitter:url" content="https://rashailagro.in/satellite/" />
                <meta property="twitter:type" content="website" />
            </Helmet> */}
      <HeadProvider>
        <Title>Fasalam Farming App: Your All-in-One Smart Farming Tool</Title>
        <Meta
          name="description"
          content="Manage your farm, get real-time data, and explore market trends with the Fasalam Farming app by Rashail Agro."
        />
        <base target="_blank" href="https://rashailagro.in/satellite/" />
        <Link rel="canonical" content="https://rashailagro.in/satellite/" />
        <Meta
          property="og:title"
          content="Fasalam Farming App: Your All-in-One Smart Farming Tool"
        />
        <Meta
          property="og:description"
          content="Manage your farm, get real-time data, and explore market trends with the Fasalam Farming app by Rashail Agro."
        />
        <Meta property="og:url" content="https://rashailagro.in/satellite/" />
        <Meta property="og:type" content="website" />
        <Meta
          name="twitter:title"
          content="Fasalam Farming App: Your All-in-One Smart Farming Tool"
        />
        <Meta
          name="twitter:description"
          content="Manage your farm, get real-time data, and explore market trends with the Fasalam Farming app by Rashail Agro."
        />
        <Meta
          property="twitter:url"
          content="https://rashailagro.in/satellite/"
        />
        <Meta property="twitter:type" content="website" />
      </HeadProvider>
      <div className="row Termconditionbackground d-flex align-items-center">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h1 className="mt-5 ">
            <span className="policy_heading">Satellite Farming </span>
            <span className="privacy_heading ">by Fasalam</span>
          </h1>
          <h2 className="text-center border_new">
            Satellite Based Farming for increased efficiency and yield.
          </h2>
        </div>
      </div>
      <div className="row justify-content-center text-center mt-5 mb-5 position-relative">
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-md-2 gap-0">
          <div className="col-12 col-md-auto">
            <h2 className="policy_heading">Precision</h2>
          </div>
          <span className="d-inline">
            <GoDotFill style={{ color: "#2fb95d" }} />
          </span>
          <div className="col-12 col-md-auto">
            <h2 className="policy_heading">Innovation</h2>
          </div>
          <span className="d-inline">
            <GoDotFill style={{ color: "#2fb95d" }} />
          </span>
          <div className="col-12 col-md-auto">
            <h2 className="policy_heading">Sustainability</h2>
          </div>
          <span className="d-inline">
            <GoDotFill style={{ color: "#2fb95d" }} />
          </span>
        </div>
        <div>
          <h2 className="text-center border_new1">
            Revolutionizing Agriculture with Space Technology.
          </h2>
        </div>
        <div>
          <div className="get_fasalam mt-3">
            <a href="https://preprod.dashboard.rashailagro.in/" target="_blank">
              <button>Get Fasalam</button>
            </a>
          </div>
        </div>
        <div className="mt-5">
          <div id="imageContainer" className="image-container">
            <img
              src={satelliteGroup1}
              alt="Satellite 1"
              className="satellite"
            />
            <img
              src={SatelliteGroup2}
              alt="Satellite 2"
              className="satellite"
            />
            <img
              src={SatelliteGroup3}
              alt="Satellite 3"
              className="satellite"
            />
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-2 justify-content-center">
        <div class=" text-center">
          <p className="staellite_started">
            <span className="line_heading"></span>
            Get Started with Satellite Farming
          </p>
          <h3 className="heading_home">
            The <span className="heading_home_color">Future of Farming</span>{" "}
            Starts Here!
          </h3>
          <div
            className="mt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-md-8">
              <img
                src={satelliteTree}
                alt="Fasalam_farming_app_community"
                className="img-fluid py-5"
              />
            </div>
          </div>
        </div>
        <div className="fasalam_expert_button_container">
          <div className="get_fasalam">
            <a href="https://preprod.dashboard.rashailagro.in/" target="_blank">
              <button>Get Fasalam</button>
            </a>
          </div>
          <div className="contac_expert">
            <button onClick={() => navigate("/contact-us/")}>
              Contact Expert
            </button>
          </div>
        </div>

        <div class="text-center mt-5">
          <p className="staellite_started">
            <span className="line_heading"></span> Our Solution Include
          </p>
          <h3 className="heading_home">
            Harnessing <span className="heading_home_color">Agriculture</span>{" "}
            with <span className="heading_home_color"> Space Technology</span>
          </h3>
          <div
            className="mt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BlogGrid blogs={satelliteCardsData} />
          </div>
        </div>
        <div class="text-center mt-5">
          <p className="staellite_started">
            <span className="line_heading"></span> Why to Choose Satellite Based
            Farming
          </p>
          <h3 className="heading_home">
            <span className="heading_home_color">Revolutionize</span> Your
            Farming Experience
          </h3>
          <div
            className="mt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-md-8">
              <img
                src={satelliteGroup}
                alt="Fasalam_farming_app_community"
                className="img-fluid py-5"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="emptydiv" style={{ height: "200px" }}></div>

      <Modal
        show={showModal1}
        onHide={handleModalClose1}
        //  scrollable
      >
        <Modal.Body className="text-center">
          <img
            src={modelimg}
            alt="Landingimg"
            className="img-fluid"
            style={{ width: "80%", height: "80%" }}
          />
          <h4 className="comingsoon_model">Coming Soon!</h4>
          <p className="paramodel">
            Stay tuned for the launch of our Fasalam App, where innovation meets
            cultivation!
          </p>

          <button
            className="buttonfunctionaity_model"
            onClick={handleModalClose1}
          >
            Back to Home
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Satelite;
